import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useReducer,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import {
  Page,
  Layout,
  Card,
  Spinner,
  BlockStack,
  InlineStack,
  Text,
  Icon,
  Button,
  Badge,
  DropZone,
  TextField,
  Select,
  InlineGrid,
  ResourceList,
  ResourceItem,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import {
  ArrowLeftIcon,
  BugIcon,
  DeleteIcon,
  DragDropIcon,
  EditIcon,
  ExternalSmallIcon,
  PlusIcon,
  SaveIcon,
} from "@shopify/polaris-icons";
import deepEqual from "deep-equal";

import { useFetch } from "./utils";

const GiftListContext = createContext({
  id: null,
  model: null,
  patchObject: null,
  modified: false,
});

const giftListApi = (lastState, { type, value }) => {
  const action = {
    loadData(state, newData) {
      return { id: state.id, model: newData, patchObject: null, error: null };
    },
    setError(state, { error }) {
      return { ...state, error };
    },
    patchRegistryData(state, newData) {
      const newModel = {
        ...state.model,
        data: { ...state.model.data, ...newData },
      };
      const newPatchObject = {
        ...state.patchObject,
        data: { ...state.patchObject?.data, ...newData },
      };
      return { ...state, model: newModel, patchObject: newPatchObject };
    },
    patchRegistryItems(state, newData) {
      const key = Object.keys(newData)[0];

      const index = state.model.products.findIndex((i) => key === i.key);

      if (index === -1) return { ...state };

      const newPatchObject = {
        ...state.patchObject,
        products: { ...state.patchObject?.products, ...newData },
      };

      const newProducts = state.model.products.reduce(
        (previousValue, currentValue, currentIndex) => {
          if (currentIndex === index) {
            return [...previousValue, { ...currentValue, ...newData[key] }];
          }
          return [...previousValue, { ...currentValue }];
        },
        []
      );

      const newModel = { ...state.model, products: [...newProducts] };

      return {
        ...state,
        model: newModel,
        patchObject: newPatchObject,
      };
    },
    patchParticipants(state, newData) {
      console.log("[dispatch] todo Participants")
      console.log(newData)
      const newModel = { ...state.model };

      const newPatchObject = {
        ...state.patchObject,
      };

      return {
        ...state,
        model: newModel,
        patchObject: newPatchObject,
      };
    },
    patchAddresses(state, newData) {
      console.log("[dispatch] todo Addresses");
      console.log(newData);
      const newModel = { ...state.model };

      const newPatchObject = {
        ...state.patchObject,
      };

      return {
        ...state,
        model: newModel,
        patchObject: newPatchObject,
      };
    },
    upsertImage(state, newData) {
      console.log("[dispatch] todo upsertImage");

      const newModel = { ...state.model, data: {...state.model.data, ...newData} };

      const newPatchObject = {
        ...state.patchObject,
        data: { ...state.patchObject?.data, ...newData },
      };

      return {
        ...state,
        model: newModel,
        patchObject: newPatchObject,
      };
    }
  }[type];
  if (!action) {
    console.error(`reducer type ${type} not implemented, no-op`);
    return lastState;
  }
  const nextState = action(lastState, value);

  nextState.unsavedChanges = !deepEqual(
    nextState.lastLoaded,
    nextState.current
  );
  return nextState;
};

const RegistryHeaderCard = () => {
  const [{ model }, dispatch] = useContext(GiftListContext);
  const authFetch = useFetch();
  
  const handleDropZoneDrop = useCallback( (_dropFiles, acceptedFiles,) =>{
      dispatch({ type: "upsertImage", value: { image: acceptedFiles[0] } });
    }, [dispatch]
  );

  const patchDooer = useCallback(async () => {

    const file = await (
                  await fetch(
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEX/AAAZ4gk3AAAACklEQVQImWNgAAAAAgAB9HFkpgAAAABJRU5ErkJggg=="
                  )
                ).blob();
                const fd = new FormData();
                fd.append("data[name]", "wasd");
                fd.append("data[registrant_first]", "x");
                fd.append("data[registrant_last]", "x");
                fd.append("data[image]", file);
                await authFetch(`/api/lists/${model.id}`, {
                  body: fd,
                  method: "PATCH",
                });


  },[])
  
  if (!model) return <></>;

  return (
    <Card>
      <BlockStack>
        <InlineGrid columns={["oneThird", "twoThirds"]}>
          <DropZone
            outline
            // overlayText="Foobar"
            labelAction={{
              content: "prebuilt patch do-er",
              onAction: async () => {
                alert("Thing Dooer");
                patchDooer();
              },
            }}
            label="Registry Image"
            accept="image/*"
            type="image"
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
          >
            {model?.data?.image && (
              <Thumbnail
                size="medium"
                alt={model.data.image.name}
                source={
                  model.data.image.url ||
                  window.URL.createObjectURL(model.data.image)
                }
              />
            )}
            <DropZone.FileUpload actionTitle="Add Image" />
          </DropZone>
          <BlockStack>
            <TextField
              label="Registry Name"
              value={model?.data?.name}
              onChange={(value) => {
                dispatch({
                  type: "patchRegistryData",
                  value: { name: value },
                });
              }}
            />
            <TextField
              multiline={4}
              label="Registry Description"
              value={model?.data?.description}
              onChange={(value) => {
                dispatch({
                  type: "patchRegistryData",
                  value: { description: value },
                });
              }}
            />
          </BlockStack>
        </InlineGrid>
        <TextField
          multiline={4}
          label="Staff Notes(not visible to customer)"
          value={model?.data?.notes}
          onChange={(value) => {
            dispatch({
              type: "patchRegistryData",
              value: { notes: value },
            });
          }}
        />
      </BlockStack>
    </Card>
  );
};

const ParticipantsList = () => {
  const [{ model }] = useContext(GiftListContext);
  const { participants } = model || [];

  if (!participants && participants.length === 0) return <></>;

  const renderParticipantItem = (p) => (
    <BlockStack>
      <InlineStack direction="row">
        <Text variant="headingSm">{`${p.firstName} ${p.lastName}`}</Text>
      </InlineStack>
      <InlineStack direction="row-reverse">
        <Text tone="subdued">{`${p.role}`}</Text>
      </InlineStack>
      {/* <Link to={`admin:/customers/${p.id.split("/")[4]}`}>
                  {p.email}
                </Link> */}
    </BlockStack>
  );

  return (
    <BlockStack>
      <ResourceList
        items={participants}
        renderItem={renderParticipantItem}
        showHeader={false}
        selectable={false}
        resourceName={{ singular: "participant", plural: "participants" }}
      />
      <Button
        onClick={() => {
          alert("todo");
        }}
      >
        <InlineStack>
          <Icon source={PlusIcon} />
          Add Manager
        </InlineStack>
      </Button>
    </BlockStack>
  );
};



const AddressSection = () => {

  const [{ model }] = useContext(GiftListContext);

  return (
    <>
      <Text variant="headingMd">Shipping Address</Text>
      <BlockStack>
        {model?.data?.contact_address && (
          <>
            <BlockStack>
              <InlineStack align="space-between">
                <Text variant="headingSm">Contact Address</Text>
                <Button variant="monochromePlain" onClick={()=>{alert("todo")}}><Icon source={EditIcon} /></Button>
              </InlineStack>
              <Text>{model.data.contact_address.address}</Text>
              {/* <Text>{model.data.before_address.address2}</Text> */}
              <Text>{model.data.contact_address.city}</Text>
              <Text>{model.data.contact_address.province}</Text>
              <Text>{model.data.contact_address.postal}</Text>
              <Text>{model.data.contact_address.countrycode}</Text>
            </BlockStack>
          </>
        )}
        {model?.data?.before_address && (
          <>
            <BlockStack>
              <Text variant="headingSm">Before Address</Text>
              <Text>{model.data.before_address.address}</Text>
              {/* <Text>{model.data.before_address.address2}</Text> */}
              <Text>{model.data.before_address.city}</Text>
              <Text>{model.data.before_address.province}</Text>
              <Text>{model.data.before_address.postal}</Text>
              <Text>{model.data.before_address.countrycode}</Text>
            </BlockStack>
          </>
        )}
        {model?.data?.after_address && (
          <>
            <BlockStack>
              <Text variant="headingSm">After Address</Text>
              <Text>{model.data.after_address.address}</Text>
              {/* <Text>{model.data.before_address.address2}</Text> */}
              <Text>{model.data.after_address.city}</Text>
              <Text>{model.data.after_address.province}</Text>
              <Text>{model.data.after_address.postal}</Text>
              <Text>{model.data.after_address.countrycode}</Text>
            </BlockStack>
          </>
        )}
      </BlockStack>
    </>
  );
}

const RegistryInfoCard = () => {

  console.log()

  return (
    <Card>
      <BlockStack>
        <Text>Registry Information</Text>
        <TextField
          type="password"
          autoComplete="off"
          label="Registry Password"
          value="placeholder"
        />
        <Text variant="headingMd">Owners & Managers</Text>
        <BlockStack>
          <ParticipantsList />
          <br/>
          <AddressSection />
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

const EventInformationCard = () => {
  const [{ model }, dispatch] = useContext(GiftListContext);
  if (!model) return <></>;

  return (
    <Card>
      <BlockStack>
        <Text variant="headingMd">Event Information</Text>
        <Select
          label="Event Type"
          value={model?.data?.event_type}
          options={[
            { label: "Placeholder" },
            { label: "Wedding", value: "Wedding" },
          ]}
          onChange={(value) => {
            dispatch({
              type: "patchRegistryData",
              value: { event_type: value },
            });
          }}
        />
        <TextField
          type="date"
          label="Event Date"
          value={model?.data?.event_date}
          onChange={(value) => {
            dispatch({
              type: "patchRegistryData",
              value: { event_date: value },
            });
          }}
        />
      </BlockStack>
      <br />
      <Button
        onClick={() => {
          alert("todo");
        }}
      >
        <InlineStack>
          <Icon source={PlusIcon} />
          Additional dates
        </InlineStack>
      </Button>
    </Card>
  );
};

const RegistryItemCard = () => {
  const [{ model }, dispatch] = useContext(GiftListContext);
  if (!model) return <></>;

  const itemRenderer = (item) => (
    <ResourceItem>
      <InlineStack wrap={false} align="center">
        <Icon source={DragDropIcon} />
        <Thumbnail
          size="large"
          source={
            item.product_data?.image?.url ||
            item.product_data.product?.featuredImage?.url
          }
        />
        <BlockStack gap="500">
          <Text variant="headingMd">{item.product_data?.displayName}</Text>
          <Text tone="subdued">{item.product_data?.title}</Text>
          <Text tone="subdued">{item.product_data?.sku}</Text>
        </BlockStack>
        <BlockStack gap="500">
          <InlineStack align="space-around">
            <TextField
              label="Wants"
              type="number"
              maxLength={68}
              step={1}
              min={item.has_quantity}
              autoSize
              autoComplete="off"
              value={item.quantity}
              onChange={(value) => {
                const d = {};
                d[item.key] = { quantity: parseInt(value, 10) };
                dispatch({
                  type: "patchRegistryItems",
                  value: d,
                });
              }}
            />
            <TextField
              label="Has"
              type="number"
              max={item.quantity}
              min={0}
              step={1}
              autoSize
              autoComplete="off"
              value={item.has_quantity}
              readOnly
            />
            <TextField
              label="Needs"
              readOnly
              step={null}
              type="number"
              autoSize
              autoComplete="off"
              value={item.quantity - item.has_quantity}
            />
            <Button
              onClick={() => {
                const d = {};
                d[item.key] = { quantity: 0, deleted: true };
                dispatch({
                  type: "patchRegistryItems",
                  value: d,
                });
              }}
            >
              <Icon source={DeleteIcon} />
            </Button>
          </InlineStack>
          <InlineStack>
            <></>
            {item.product_data?.sellableOnlineQuantity && (
              <Badge tone="success">{`${item.product_data?.sellableOnlineQuantity} in stock`}</Badge>
            )}
            <></>
          </InlineStack>
        </BlockStack>
      </InlineStack>
    </ResourceItem>
  );


  return (
    <Card>
      <ResourceList
        showHeader
        headerContent="Foobar"
        selectable={false}
        items={model?.products.filter((x) => !x?.deleted)}
        renderItem={itemRenderer}
        emptyState={
          <>
            <Button
              onClick={() => {
                alert("todo");
              }}
            >
              Add Item to Registry
            </Button>
          </>
        }
      />
      <Button
        onClick={() => {
          alert("todo");
        }}
      >
        <InlineStack>
          <Icon source={PlusIcon} />
          Add item to registry
        </InlineStack>
      </Button>
    </Card>
  );
};

const RegistryOrdersCard = () => {
  const { model } = useContext(GiftListContext);
  if (!model) return <></>;

  return (
    <Card>
      <ResourceList
        showHeader
        headerContent="Contributions"
        items={model?.contributions}
        renderItem={(item) => (
          <ResourceItem>
            <Tooltip content={JSON.stringify(item, null, 2)}>
              <Icon source={BugIcon} />
            </Tooltip>
            <Text>{JSON.stringify(item, null, 2)}</Text>
          </ResourceItem>
        )}
      />
    </Card>
  );
};

const PageLayout = () => {
  const authFetch = useFetch();
  const [{ id, model, patchObject }, dispatch] =
    useContext(GiftListContext);

  const saveFunction = useCallback(async () => {
    const response = await authFetch(`/api/lists/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(patchObject),
    }).then(async (r) => r.json());

    if (response?.error) {
      dispatch({ type: "setError", value: response.error });
    } else {
      dispatch({ type: "loadData", value: response });
    }
  }, [id, authFetch, patchObject, dispatch]);

  return (
    <>
      {!model && <Spinner />}
      {model && (
        <Page
          fullWidth
          backAction={{ content: "Gift Lists", url: "/gift_list" }}
          compactTitle
          title={<Text variant="headingMd">{model?.data?.name || id}</Text>}
          titleMetadata={
            <InlineStack align="center">
              <Badge tone="success">Placeholder</Badge>
              <Button variant="plain" icon={ExternalSmallIcon} />
              <Button variant="primary" icon={SaveIcon} onClick={saveFunction}>
                THE UGLIEST AND LONGEST SAVE BUTTON EVER
              </Button>
            </InlineStack>
          }
          breadcrumbs={<Button icon={ArrowLeftIcon} />}
          subtitle={`created on ${model?.created_at}`}
        >
          <Layout>
            <Layout.Section>
              <RegistryHeaderCard />
              <RegistryItemCard />
              <RegistryOrdersCard />
            </Layout.Section>
            <Layout.Section variant="oneThird">
              <EventInformationCard />
              <RegistryInfoCard />
              {/* <Card>
                <Text variant="headingMd">Audit Log</Text>
                <ResourceList
                  showHeader
                  resourceName={{ singular: "Event", plural: "Events" }}
                  items={model?.data.audit_trail}
                  renderItem={(item) => (
                    <ResourceItem>
                      <InlineStack wrap={false} gap="1000">
                        <Text>
                          {item.date}
                          {/* {Intl.DateTimeFormat(navigator.languages).format(
                            Date.parse()
                          )}
                        </Text>
                        <Text>{item.msg}</Text>
                        <Text>
                          {item.shopify_customer_id || item.ip_address}
                        </Text>
                        <Tooltip content={JSON.stringify(item, null, 2)}>
                          <Icon source={BugIcon} />
                        </Tooltip>
                      </InlineStack>
                    </ResourceItem>
                  )}
                />
              </Card> */}
            </Layout.Section>
          </Layout>
        </Page>
      )}
    </>
  );
};

const GiftListEditPage = () => {
  const { id } = useParams();
  const authFetch = useFetch();
  const [loaded, setLoaded] = useState(false);
  const [content, dispatch] = useReducer(giftListApi, {
    id,
    model: null,
    patchObject: null,
  });

  useEffect(() => {
    const getData = async () =>
      authFetch(`/api/lists/${id}`).then(async (r) => r.json());

    if (loaded === false && id) {
      getData()
        .then((jsonResponse) => {
          if (jsonResponse?.error) {
            dispatch({ type: "setError", value: jsonResponse.error });
          } else {
            dispatch({ type: "loadData", value: jsonResponse });
          }
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [loaded, authFetch, dispatch, id]);

  return (
    <GiftListContext.Provider value={[content, dispatch]}>
      <PageLayout />
      <hr />
      <pre>{JSON.stringify(content, null, 2)}</pre>
      <hr />
    </GiftListContext.Provider>
  );
};

// export const GiftListPageOLD = () => {
//   const { id } = useParams();
//   const authFetch = useFetch();
//   const redirect = useRedirect();

//   const [loading, setLoading] = useState(true);
//   const [title] = useState(id);
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);

//   const loadData = useCallback(
//     (jsonResponse) => {
//       if (jsonResponse?.error) {
//         setError(jsonResponse.error);
//       } else {
//         setData(jsonResponse);
//       }
//     },
//     [setData, setError]
//   );

//   useEffect(() => {
//     const getData = async () =>
//       authFetch(`/api/lists/${id}`).then(async (r) => r.json());

//     if (loading) {
//       getData()
//         .then((jsonResponse) => loadData(jsonResponse))
//         .finally(() => {
//           setLoading(!loading);
//         });
//     }
//   }, [loading, setLoading, authFetch, loadData, id]);

//   const deleteCallback = useCallback(() => {
//     authFetch(`/api/lists/${id}`, { method: "DELETE" })
//       .then(() => redirect("/gift_list"))
//       .catch((d) => {
//         console.error(d);
//         // eslint-disable-next-line no-alert
//         alert(d);
//       });
//   }, [authFetch, id, redirect]);

//   const syncCallback = useCallback(() => {
//     authFetch(`/api/lists/${id}/sync`)
//       .then(async (r) => r.json())
//       .then((jsonResponse) => loadData(jsonResponse))
//       .catch((d) => {
//         console.error(d);
//         // eslint-disable-next-line no-alert
//         alert(d);
//       });
//   }, [authFetch, id, loadData]);

//   const RegistryHeaderCard = () => {
//     if (!data) return <></>;

//     return (
//           <Card>
//             <BlockStack>
//               <InlineGrid columns={["oneThird", "twoThirds"]}>
//                 <DropZone
//                   outline
//                   overlayText="Foobar"
//                   label="Registry Image"
//                   accept="image/*"
//                   type="image"
//                 >
//                   <DropZone.FileUpload actionTitle="Add Image" />
//                 </DropZone>
//                 <BlockStack>
//                   <TextField label="Registry Name" value={data?.name} />
//                   <TextField
//                     multiline={4}
//                     label="Registry Description"
//                     value={data?.description}
//                   />
//                 </BlockStack>
//               </InlineGrid>
//               <TextField
//                 multiline={4}
//                 label="Staff Notes(not visible to customer)"
//                 value={data?.notes}
//               />
//             </BlockStack>
//           </Card>
//     );
//   };

//   const RegistryInfoCard = () => {
//     if (!data) return <></>;

//     return (
//       <Card>
//         <BlockStack>
//           <Text>Registry Information</Text>
//           <TextField
//             type="password"
//             autoComplete="off"
//             label="Registry Password"
//             value="placeholder"
//           />
//           <Text variant="headingSm">Admins</Text>
//           {data?.participants.map((p) => (
//             <>
//               <BlockStack wrap={false}>
//                 <InlineStack direction="row">
//                   <Text variant="headingSm">{`${p.shopify_customer.data.firstName} ${p.shopify_customer.data.lastName}`}</Text>
//                 </InlineStack>
//                 <InlineStack direction="row-reverse">
//                   <Text tone="subdued">{`${p.role}`}</Text>
//                 </InlineStack>
//               </BlockStack>
//               {p.shopify_customer.data.external_id ? (
//                 <Link
//                   to={`admin:/customers/${p.shopify_customer.data.external_id}`}
//                 >
//                   {p.shopify_customer.data.email}
//                 </Link>
//               ) : (
//                 <Text>{p.shopify_customer.data.email}</Text>
//               )}
//             </>
//           ))}
//         </BlockStack>
//       </Card>
//     );
//   };

//   const EventInformationCard = () => {
//     if (!data) return <></>;

//     return (
//       <Card>
//         <BlockStack>
//           <Text>Event Information</Text>
//           <Select label="Event Type" options={[{ label: "Placeholder" }]} />
//           {/* <TextField type="date" label="Event Date" value={data.event_date} /> */}
//         </BlockStack>
//       </Card>
//     );
//   };

//   const RegistryItemCard = () => {
//     if (!data) return <></>;

//     return (
//       <Card>
//         <ResourceList
//           showHeader
//           headerContent="Foobar"
//           items={data.products}
//           renderItem={(item) => {
//             // console.log(item);
//             return (
//               <ResourceItem>
//                 <InlineStack wrap={false} align="center">
//                   <Icon source={DragDropIcon} />
//                   <Tooltip content={JSON.stringify(Object.keys(item), null, 2)}>
//                     <Icon source={BugIcon} />
//                   </Tooltip>
//                   <Tooltip
//                     content={JSON.stringify(
//                       Object.keys(item.product_data),
//                       null,
//                       2
//                     )}
//                   >
//                     <Icon source={BugIcon} />
//                   </Tooltip>
//                   {item.product_data.product && (
//                     <Tooltip
//                       content={JSON.stringify(
//                         Object.keys(item.product_data.product),
//                         null,
//                         2
//                       )}
//                     >
//                       <Icon source={BugIcon} />
//                     </Tooltip>
//                   )}
//                   <Thumbnail
//                     size="large"
//                     source={
//                       item.product_data?.image?.url ||
//                       item.product_data.product?.featuredImage?.url
//                     }
//                   />
//                   <BlockStack gap="500">
//                     <Text variant="headingMd">
//                       {item.product_data?.displayName}
//                     </Text>
//                     <Text tone="subdued">{item.product_data?.title}</Text>
//                     <Text tone="subdued">{item.product_data?.sku}</Text>
//                   </BlockStack>
//                   <BlockStack gap="500">
//                     <InlineStack>
//                       <Box maxWidth="70">
//                         <TextField
//                           label="Wants"
//                           type="number"
//                           autoSize
//                           autoComplete="off"
//                           value={item.quantity}
//                         />
//                       </Box>
//                       <Box maxWidth="70">
//                         <TextField
//                           label="Has"
//                           type="number"
//                           autoSize
//                           autoComplete="off"
//                           value={item.has_quantity}
//                         />
//                       </Box>
//                       <Box maxWidth="70">
//                         <TextField
//                           label="Needs"
//                           readOnly
//                           type="number"
//                           autoSize
//                           autoComplete="off"
//                           value={item.quantity - item.has_quantity}
//                         />
//                       </Box>
//                     </InlineStack>
//                     <InlineStack>
//                       <></>
//                       {item.product_data?.sellableOnlineQuantity && (
//                         <Badge tone="success">{`${item.product_data?.sellableOnlineQuantity} in stock`}</Badge>
//                       )}
//                       <></>
//                     </InlineStack>
//                   </BlockStack>
//                 </InlineStack>
//               </ResourceItem>
//             );
//           }}
//         >
//           <></>
//         </ResourceList>
//       </Card>
//     );
//   };

//   const RegistryOrdersCard = () => {
//     if (!data) return <></>;

//     return (
//       <Card>
//         <ResourceList
//           showHeader
//           headerContent="Contributions"
//           items={data.contributions}
//           renderItem={(item) => {
//             // console.log(item);
//             return (
//               <ResourceItem>
//                 <Tooltip content={JSON.stringify(item, null, 2)}>
//                   <Icon source={BugIcon} />
//                 </Tooltip>
//                 <Text>{JSON.stringify(item, null, 2)}</Text>
//               </ResourceItem>
//             );
//           }}
//         />
//       </Card>
//     );
//   };

//   console.log(data);

//   return (
//     <>
//       {loading && <Spinner />}
//       {!loading && (
//         <Page
//           fullWidth
//           backAction={{ content: "Gift Lists", url: "/gift_list" }}
//           compactTitle
//           title={<Text variant="headingMd">{data?.name || id}</Text>}
//           titleMetadata={
//             <InlineStack align="center">
//               <Badge tone="success">Placeholder</Badge>
//               <Button variant="plain" icon={ExternalSmallIcon} />
//             </InlineStack>
//           }
//           breadcrumbs={<Button icon={ArrowLeftIcon} />}
//           subtitle={data.created_at}
//         >
//           <Layout>
//             <Layout.Section>
//               <RegistryHeaderCard />
//               <RegistryItemCard />
//               <RegistryOrdersCard />
//             </Layout.Section>
//             <Layout.Section variant="oneThird">
//               <EventInformationCard />
//               <RegistryInfoCard />
//               <Card>
//                 <Text variant="headingMd">Audit Log</Text>
//                 <ResourceList
//                   showHeader
//                   resourceName={{ singular: "Event", plural: "Events" }}
//                   items={data?.data.audit_trail}
//                   renderItem={(item) => (
//                     <ResourceItem>
//                       <InlineStack wrap={false} gap="1000">
//                         <Text>
//                           {item.date}
//                           {/* {Intl.DateTimeFormat(navigator.languages).format(
//                             Date.parse()
//                           )} */}
//                         </Text>
//                         <Text>{item.msg}</Text>
//                         <Text>
//                           {item.shopify_customer_id || item.ip_address}
//                         </Text>
//                         <Tooltip content={JSON.stringify(item, null, 2)}>
//                           <Icon source={BugIcon} />
//                         </Tooltip>
//                       </InlineStack>
//                     </ResourceItem>
//                   )}
//                 />
//               </Card>
//             </Layout.Section>
//           </Layout>
//           <Text>{JSON.stringify(data, null, 2)}</Text>
//         </Page>
//       )}
//     </>
//   );
// };

export default GiftListEditPage;
