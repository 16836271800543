import React, { useEffect, useState, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useFetch, useProfile, Link } from "../utils";
import StyleIsolation from "../components/StyleIsolation";
import CreatePagination from "./components/CreatePagination";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";
import { SubscriptionContext } from "../components/SubscriptionContext";

function ReverseStock() {
  const authFetch = useGiftReggieFetch();
  const baseFetch = useFetch();
  const [profile] = useProfile();
  const { readOnly } = useContext(SubscriptionContext);

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);

  const { id } = useParams();

  useEffect(() => {
    if (loaded !== id) {
      const url = new URL(`/reggie/stock/${id}`, window.location);
      url.searchParams.append("page", page || 1);

      authFetch(url, { headers: { Accept: "application/json" } })
        .then((response) => {
          setData(response);
          setLoaded(id);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Error getting page data.");
        });
    }
  }, [authFetch, id, loaded, page]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      authFetch(`/reggie/stock/${id}`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Data after Submit", ex);
        });
    },
    [authFetch, id]
  );

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  const exportCSV = useCallback(() => {
    baseFetch(`/reggie/stock/${id}`, {
      method: "POST",
      body: new URLSearchParams([["action", "export"]]),
    })
      .then((r) => r.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.body.appendChild(document.createElement("a"));
        a.href = url;
        a.download = "stock-product-status-export.csv";
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.remove();
        }, 60000);
      });
  }, [baseFetch, id]);

  return data && profile ? (
    <StyleIsolation>
      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
            <h3>
              <Link to="/stock">Stock Status</Link> &gt;{" "}
              {data.HANDLE ? <>{data.HANDLE}</> : <>#{data.ID}</>}
            </h3>
            <hr />
            <p>
              Here you can see the listing of all registries that have the
              product &quot;{data.TITLE}&quot; in it.
            </p>
          </div>
        </div>

        <div className="row-fluid" style={{ marginTop: "12px" }}>
          <div className="span12">
            <table className="table table-striped registry-table">
              <thead>
                <tr>
                  <th id="heading-id">ID NUMBER</th>
                  <th id="heading-name">LIST NAME</th>
                  <th id="heading-type">LIST TYPE</th>
                  <th id="heading-email">EMAIL</th>
                  <th id="heading-registrant">REGISTRANT</th>
                  <th id="heading-coregistrant">CO-REGISTRANT</th>
                  <th id="heading-created">DATE CREATED</th>
                  <th id="heading-event">EVENT DATE</th>
                  <th>STATUS</th>
                  <th>WANTS</th>
                  <th>HAS</th>
                  <th>NEEDS</th>
                </tr>
              </thead>
              <tbody>
                {data.REGISTRIES?.length ? (
                  data?.REGISTRIES?.map((innerData) => (
                    <tr key={innerData.ID}>
                      <td>
                        <Link to={`/registries/${innerData.ID}`}>
                          #{innerData.ID}
                        </Link>
                      </td>
                      <td style={{ maxWidth: "200px" }}>{innerData.NAME}</td>
                      <td>{innerData.TYPE ? <>{innerData.TYPE}</> : <>?</>}</td>
                      <td>{innerData.EMAIL}</td>
                      <td>{innerData.REGISTRANT}</td>
                      <td>
                        {innerData.COREGISTRANT ? (
                          <>{innerData.COREGISTRANT}</>
                        ) : (
                          <>None</>
                        )}
                      </td>
                      <td>{innerData.CREATED}</td>
                      <td>{innerData.EVENT}</td>
                      <td>{innerData.STATUS}</td>
                      <td>{innerData.WANTS}</td>
                      <td>{innerData.HAS}</td>
                      <td>{innerData.NEEDS}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      There are currently no registries that hold this product.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div id="registry-pagination" style={{ textAlign: "center" }} />
            <CreatePagination
              maxPages={data.MAX_PAGES}
              page={page}
              setPage={updatePage}
            />
          </div>

          <div id="registry-pagination" style={{ textAlign: "center" }} />
          <CreatePagination
            maxPages={data.MAX_PAGES}
            page={page}
            setPage={updatePage}
          />
        </div>

        <form method="post" onSubmit={submit}>
          <div className="row-fluid">
            <div className="span12">
              <button
                className="btn btn-success"
                name="action"
                value="export"
                type="button"
                onClick={exportCSV}
                disabled={readOnly}
              >
                Export Stock as CSV
              </button>
            </div>
          </div>
        </form>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <ReverseStock />
  </LegacyMessageContext>
);
