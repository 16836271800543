import React, {useEffect, useState} from "react";

import {
  Page,
  Layout,
  IndexTable,
  Button,
  Text,
} from "@shopify/polaris";

import { useFetch } from "./utils";

import GiftListSettings from "./components/GiftListSettings";

const TYPE_TRANSLATIONS = {
  "GiftList::Registry::Legacy" : "Registry"
}


const GiftListTable = () => {
  const authFetch = useFetch();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () =>
      authFetch("/api/lists").then(async (r) => r.json());

    if (loading) {
      getData()
        .then((jsonResponse) => {
          if (Array.isArray(jsonResponse)) {
            setList(jsonResponse);
            setError(null);
          } else {
            setList([]);
            setError(jsonResponse?.error);
          }
        })
        .finally(() => {
          setLoading(!loading);
        });
    }
  }, [loading, setLoading, authFetch, setError]);

  const Rows = list.map((l) => (
    <IndexTable.Row>
      <IndexTable.Cell>
        <Button url={`/gift_list/${l.id}`}>{l.id}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>{l.created_at}</IndexTable.Cell>
      <IndexTable.Cell>{l.data.event_date}</IndexTable.Cell>
      <IndexTable.Cell>REGISTRY OWNER HERE</IndexTable.Cell>
      <IndexTable.Cell>{l.products.length}</IndexTable.Cell>
      <IndexTable.Cell>REGISTRY VALUE HERE</IndexTable.Cell>
      <IndexTable.Cell>{l.contributions.length}</IndexTable.Cell>
      <IndexTable.Cell>SHIPPING METHOD HERE</IndexTable.Cell>
      <IndexTable.Cell>{TYPE_TRANSLATIONS[l.type]}</IndexTable.Cell>
      <IndexTable.Cell>STATUS HERE</IndexTable.Cell>
    </IndexTable.Row>
  ));


  return (
    <>
      {/* <IndexFilters showEditColumnsButton  /> */}
      <IndexTable
        loading={loading}
        resourceName={{ singular: "list", plural: "lists" }}
        itemCount={list.length}
        selectable={false}
        emptyState={<Text tone="subdued" >No Registries</Text>}
        headings={[
          { title: "ID" },
          { title: "Created At" },
          { title: "Event Date" },
          { title: "Registry Owner" },
          { title: "Items" },
          { title: "Value" },
          { title: "Order Count" },
          { title: "Shipping Method" },
          { title: "List Type" },
          { title: "Status" },
        ]}
      >
        {error && (<p>{error}</p>)}
        {list.length > 0 && Rows}
      </IndexTable>
    </>
  );
}


const GiftListPage = () => (
  <Page fullWidth>
    <Layout>
      <Layout.Section>
        <GiftListSettings />
      </Layout.Section>
      <Layout.Section>
        <GiftListTable/>
      </Layout.Section>
    </Layout>
  </Page>
);

export default GiftListPage;
