import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useFetch, Link } from "../utils";
import StyleIsolation from "../components/StyleIsolation";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";
import LoadingAnimation from "../components/LoadingAnimation";
import { SubscriptionContext } from "../components/SubscriptionContext";


function Sorter({ forOrder, order, dir, setOrder, setDir }) {
  const newDir = forOrder === order && dir === "asc" ? "desc" : "asc";
  const dirIcon = newDir === "desc" ? "\u2B06\uFE0F" : "\u2B07\uFE0F";
  const icon = forOrder === order ? dirIcon : "\u2935\uFE0F";

  const click = useCallback(() => {
    setOrder(forOrder);
    setDir(newDir);
  }, [forOrder, newDir, setDir, setOrder]);

  return (
    <button
      type="button"
      className="button-is-link"
      title={`Sort ${newDir}`}
      onClick={click}
      style={{ marginLeft: "1em" }}
    >
      {icon}
    </button>
  );
}

function CreatePagination({ maxPages, paginationSize = 1, page, setPage }) {
  if (maxPages > 1) {
    const currentPage = +page || 1;
    const minPage = Math.max(1, currentPage - paginationSize);
    const maxPage = Math.min(currentPage + paginationSize, maxPages);
    return (
      <>
        {" - "}
        {[...Array(maxPage - minPage + 1).keys()].map((offset) => (
          <React.Fragment key={`page-${minPage + offset}`}>
            {minPage + offset === page ? (
              <b>{minPage + offset}</b>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setPage(minPage + offset);
                }}
                className="button-is-link"
              >
                {minPage + offset}
              </button>
            )}
            {" - "}
          </React.Fragment>
        ))}
      </>
    );
  }
  return null;
}

function Registries() {
  const BASE_URL = "/reggie/registries";

  const [data, setData] = useState(null);
  const fetch = useGiftReggieFetch();
  const baseFetch = useFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const usp = new URLSearchParams(location.search);
  const [loaded, setLoaded] = useState(false);
  const { readOnly } = useContext(SubscriptionContext);

  const [showRegistryCreate, setShowRegistryCreate] = useState(false);
  const toggleShowRegistryCreate = useCallback(
    () => setShowRegistryCreate(!showRegistryCreate),
    [showRegistryCreate]
  );

  const defaultStatus = "open";
  const defaultSearchType = "names";

  const [startDate, setStartDate] = useState(() => usp.get("start-date"));
  const [endDate, setEndDate] = useState(() => usp.get("end-date"));
  const [items, setItems] = useState(() => Number(usp.get("items")) || 50);
  const [page, setPage] = useState(() => Number(usp.get("page")) || 1);
  const [status, setStatus] = useState(() => usp.get("status") || defaultStatus);
  const [query, setQuery] = useState(() => usp.get("query"));
  const [searchType, setSearchType] = useState(() => usp.get("search-type") || defaultSearchType);
  const [order, setOrder] = useState(() => usp.get("order"));
  const [dir, setDir] = useState(() => usp.get("dir"));

  const [inputQuery, setInputQuery] = useState(() => usp.get("query"));

  const urlWithParameters = useMemo(() => {
    const u = new URL(BASE_URL, window.location);
    if (startDate) {
      u.searchParams.set("start-date", startDate);
    }
    if (endDate) {
      u.searchParams.set("end-date", endDate);
    }
    if (items && String(items) !== "50") {
      u.searchParams.set("items", items);
    }
    if (page && String(page) !== "1") {
      u.searchParams.set("page", page);
    }
    if (status) {
      u.searchParams.set("status", status);
    }
    if (query) {
      u.searchParams.set("query", query);
    }
    if (searchType) {
      u.searchParams.set("search-type", searchType);
    }
    if (order) {
      u.searchParams.set("order", order);
    }
    if (dir) {
      u.searchParams.set("dir", dir);
    }
    return u;
  }, [dir, endDate, items, query, searchType, order, page, startDate, status]);

  // update propagate url changes to window
  useEffect(() => {
    navigate(urlWithParameters.search, { replace: true });
  }, [navigate, urlWithParameters]);

  useEffect(() => {
    if (!loaded) {
      fetch(urlWithParameters, {
        headers: { Accept: "application/json" },
      })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Registries list Data", ex);
        })
        .finally(() => {
          setLoaded(true);
        });
    }
  }, [fetch, loaded, urlWithParameters]);

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setQuery(inputQuery);
      updatePage(1);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [inputQuery, updatePage]);

  const itemsChange = useCallback(
    (e) => {
      setItems(e.target.value);
      if (Number(items) !== Number(e.target.value)) {
        setPage(1);
      }
      setLoaded(false);
    },
    [items]
  );

  const updateDir = useCallback(
    (newDir) => {
      setDir(newDir);
      updatePage(1);
    },
    [updatePage]
  );

  const submit = useCallback(
    // eslint-disable-next-line consistent-return
    (e) => {
      e.preventDefault();
      if(readOnly)
        return false

      const formData = new FormData(e.target);

      const dateFromInputElement = formData.get("registry-date");
      try {
        const perlFriendlyDate =
          DateTime.fromISO(dateFromInputElement).toFormat("MM/dd/yyyy");
        formData.set("registry-date", perlFriendlyDate);
      } catch (ex) {
        console.log(
          `[GiftReggie] converting "${dateFromInputElement}" didn't go down so well`,
          ex
        );
      }
      // setData(null);
      fetch(BASE_URL, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        // .then((r) => r.json())
        // .then((nextData) => {
        //   setData(nextData);
        // })
        // .catch((ex) => {
        //   console.error("Fetching Data after Submit", ex);
        // })
        .finally(() => {
          e.target.reset();
          setShowRegistryCreate(false);
          setLoaded(false);
        });
    },
    [fetch, readOnly]
  );

  const discard = useCallback((e) => {
    e.target.form.reset();
    setShowRegistryCreate(false);
  }, []);

  const exportCSV = useCallback(() => {
    baseFetch("/reggie/shopify_jwt")
      .then((r) => r.text())
      .then((jwt) => {
        const exportURL = new URL(urlWithParameters);
        exportURL.searchParams.set("action", "export");
        exportURL.searchParams.set("shopify_jwt", jwt);
        window.open(exportURL, "_blank");
      })
      .catch((ex) => {
        console.error("Fetching Registries list Data", ex);
      });
  }, [baseFetch, urlWithParameters]);

  // eslint-disable-next-line no-nested-ternary
  return data && !Object.prototype.hasOwnProperty.call(data, "CODE") ? (
    <StyleIsolation>
      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
            <h3>Registries</h3>
            <hr />
          </div>
        </div>
        <div className="row-fluid">
          <div className="span12">
            Show{" "}
            <select
              id="registry-status"
              style={{ margin: 0, width: "auto" }}
              value={status}
              onChange={(e) => {
                setStatus(e.currentTarget.value);
                updatePage(1);
              }}
            >
              <option>open</option>
              <option>closed</option>
              <option>all</option>
            </select>{" "}
            registries with{" "}
            <select
              id="registry-search-type"
              style={{ margin: 0, width: "auto" }}
              value={searchType}
              onChange={(e) => {
                setSearchType(e.currentTarget.value);
                updatePage(1);
              }}
            >
              <option>names</option>
              <option>registrants</option>
              <option>emails</option>
              <option>search</option>
            </select>{" "} 
            like{" "}
            <input
              id="registry-search-query"
              type="text"
              style={{ margin: 0, width: "128px" }}
              placeholder="anything"
              value={inputQuery || ""}
              onChange={(e) => setInputQuery(e.currentTarget.value)}
            />{" "}
            that are scheduled between{" "}
            <input
              type="date"
              style={{ margin: 0, width: "128px" }}
              id="start-date"
              placeholder="the prehistoric"
              onChange={(e) => {
                const match = /^(\d\d\d\d)-(\d\d)-(\d\d)$/.exec(e.target.value);
                setStartDate(
                  match ? `${match[2]}/${match[3]}/${match[1]}` : ""
                );
                updatePage(1);
              }}
            />{" "}
            and{" "}
            <input
              style={{ margin: 0, width: "128px" }}
              type="date"
              id="end-date"
              className="datepicker"
              placeholder="the far future"
              onChange={(e) => {
                const match = /^(\d\d\d\d)-(\d\d)-(\d\d)$/.exec(e.target.value);
                setEndDate(match ? `${match[2]}/${match[3]}/${match[1]}` : "");
                updatePage(1);
              }}
            />{" "}
            <button
              className="btn"
              id="filter-clear"
              type="button"
              onClick={() => {
                setStatus(defaultStatus);
                setStartDate("");
                setEndDate("");
                setQuery("");
                setInputQuery("");
                setSearchType(defaultSearchType);
                updatePage(1);
              }}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="row-fluid" style={{ marginTop: "12px" }}>
          <div className="span12">
            <table className="table table-striped registry-table">
              <thead>
                <tr>
                  <th id="heading-id">
                    ID NUMBER
                    <Sorter
                      forOrder="id"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-name">
                    LIST NAME
                    <Sorter
                      forOrder="name"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-type">
                    LIST TYPE
                    <Sorter
                      forOrder="type"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-email">
                    EMAIL
                    <Sorter
                      forOrder="email"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-registrant">
                    REGISTRANT
                    <Sorter
                      forOrder="registrant"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-coregistrant">
                    CO-REGISTRANT
                    <Sorter
                      forOrder="coregistrant"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-created">
                    DATE CREATED{" "}
                    <Sorter
                      forOrder="created"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th id="heading-event">
                    EVENT DATE{" "}
                    <Sorter
                      forOrder="event"
                      dir={dir}
                      order={order}
                      setDir={updateDir}
                      setOrder={setOrder}
                    />
                  </th>
                  <th>STATUS</th>
                  <th>ITEMS</th>
                </tr>
              </thead>
              {data.REGISTRIES && data.REGISTRIES.length > 0 && (
                <tbody>
                  {data.REGISTRIES.map(
                    ({
                      ID,
                      TYPE,
                      NAME,
                      EMAIL,
                      REGISTRANT,
                      COREGISTRANT,
                      CREATED,
                      EVENT,
                      STATUS,
                      ITEMS,
                    }) => (
                      <tr key={ID}>
                        <td>
                          <Link to={`/registries/${ID}`}>#{ID}</Link>
                        </td>
                        <td style={{ maxWidth: "200px" }}>{NAME}</td>
                        <td>{TYPE}</td>
                        <td>{EMAIL}</td>
                        <td>{REGISTRANT}</td>
                        <td>{COREGISTRANT || "None"}</td>
                        <td>{CREATED}</td>
                        <td>{EVENT}</td>
                        <td>{STATUS}</td>
                        <td>{ITEMS}</td>
                      </tr>
                    )
                  )}
                </tbody>
              )}
              <tfoot>
                <tr>
                  <td colSpan={10} className="add-hidden">
                    <button type="button" className="button-is-link"
                      onClick={toggleShowRegistryCreate}
                    >
                      &#x2795;&#xFE0F; Click here to add a registry to this
                      list.
                    </button>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div
              className="registry-pagination"
              style={{ textAlign: "center" }}
            >
              <CreatePagination
                maxPages={data.MAX_PAGES}
                paginationSize={items}
                page={page}
                setPage={updatePage}
              />
            </div>
          </div>
        </div>
        <div className="row-fluid">
          <div className="span6">
            <p>
              <button
                id="export-csv"
                className="btn btn-reggie"
                type="button"
                onClick={exportCSV}
                disabled={readOnly}
              >
                Export Registries as CSV
              </button>
            </p>
          </div>

          <div className="span6" style={{ textAlign: "right" }}>
            Per Page{" "}
            <select
              id="page-selector"
              style={{ width: "auto" }}
              onChange={itemsChange}
            >
              <option>50</option>
              <option>100</option>
              <option>200</option>
            </select>
          </div>
        </div>

        {showRegistryCreate && (
          <div className="add-shown" style={{ display: "24px" }}>
            <form method="post" onSubmit={submit}>
              <div className="row-fluid">
                <div className="span12" style={{ textAlign: "center" }}>
                  <h3>Create Registry</h3>
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Name</div>
                <div className="span8">
                  <input type="text" name="registry-name" required />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Image</div>
                <div className="span8">
                  <input type="file" name="registry-image" />
                </div>
              </div>

              {data.TYPES && data.TYPES.length > 0 && (
                <div className="row-fluid">
                  <div className="span4">Type</div>
                  <div className="span8">
                    <select name="registry-type">
                      {data.TYPES.map(({ NAME }) => (
                        <option key={NAME}>{NAME}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="row-fluid">
                <div className="span4">Registrant First Name</div>
                <div className="span8">
                  <input
                    type="text"
                    name="registry-registrant-first-name"
                    required
                  />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Registrant Last Name</div>
                <div className="span8">
                  <input
                    type="text"
                    name="registry-registrant-last-name"
                    required
                  />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Event Date</div>
                <div className="span8">
                  <input
                    type="date"
                    name="registry-date"
                    className="datepicker"
                    required
                  />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact Email</div>
                <div className="span8">
                  <input type="email" name="registry-contact-email" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact Address</div>
                <div className="span8">
                  <input type="text" name="registry-contact-address" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact City</div>
                <div className="span8">
                  <input type="text" name="registry-contact-city" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact Province</div>
                <div className="span8">
                  <input type="text" name="registry-contact-province" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact Country</div>
                <div className="span8">
                  <input type="text" name="registry-contact-country" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact Postal</div>
                <div className="span8">
                  <input type="text" name="registry-contact-postal" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span4">Contact Phone</div>
                <div className="span8">
                  <input type="text" name="registry-contact-phone" />
                </div>
              </div>
              <div className="row-fluid">
                <div className="span12">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={discard}
                  >
                    Discard Changes
                  </button>{" "}
                  <button
                    id="add-registry-button"
                    className="btn btn-success"
                    type="submit"
                  >
                    Add Registry
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </LegacyContainer>
    </StyleIsolation>
  ) : (
    <LoadingAnimation />
  );
}

export default () => (
  <LegacyMessageContext>
    <Registries />
  </LegacyMessageContext>
);
