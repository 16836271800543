/* eslint-disable no-console */
import React, { useCallback, useState, useContext } from "react";

import {
  Page,
  Layout,
  Card,
  FormLayout,
  TextField,
  Button,
  Form,
  InlineStack,
  InlineError,
  FooterHelp,
  Link,
  Text
} from "@shopify/polaris";
import { useLocation, useNavigate } from "react-router-dom";

import { useFetch, useRedirect } from "../utils";

import PlanFeatureMatrix from "../components/PlanFeatureMatrix";
import SubscriptionHistoryTable from "../components/SubscriptionHistoryTable";
import PermissionGuard from "../components/Permissions";
import { SubscriptionContext } from "../components/SubscriptionContext";
import { useCreateToast } from "../components/OverlayManager";
import CreditsWidget from "../components/CreditsWidget";

function SubscriptionsContent() {
  const authFetch = useFetch();
  const redirect = useRedirect();
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);

  const { reload } = useContext(SubscriptionContext);

  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeIsValid, setDiscountCodeIsValid] = useState(true);
  const [discountErrorMessage, setDiscountErrorMessage] = useState(null);
  const [isSubmittingDiscount, setIsSubmittingDiscount] = useState(false);
  const handleDiscountCodeChange = useCallback(
    (value) => setDiscountCode(value),
    []
  );
  const createToast = useCreateToast();

  const handleDiscountCodeSubmit = useCallback(() => {
    if (!discountCode) {
      setDiscountErrorMessage("Please enter a discount code");
      setDiscountCodeIsValid(false);
      return false;
    }
    if (discountCode.length < 10) {
      setDiscountErrorMessage(
        "Discount codes must be at least 10 characters long"
      );
      setDiscountCodeIsValid(false);
      return false;
    }

    console.log(
      `Initiating Subscribe action with discount code ${discountCode}`
    );
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscription: { discount_code: discountCode },
      }),
    };
    setIsSubmittingDiscount(true);
    authFetch("/api/subscriptions", requestOptions)
      .then(async (response) => {
        const subscriptionsData = await response.json();
        // check for error response
        if (!response.ok || !subscriptionsData) {
          console.log("Error handling subscribe request");
          // get error message from body or default to response status
          const error =
            (subscriptionsData && subscriptionsData.message) ||
            "Invalid request";
          throw error;
        } else if (subscriptionsData.message) {
          createToast({ content: `${subscriptionsData.message}` });
        }
        if (subscriptionsData.active_subscriptions) {
          console.log(`Successfully subscribed to plan`);
        }
        if (subscriptionsData.redirect_to) {
          redirect(subscriptionsData.redirect_to);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setDiscountErrorMessage(error);
        setDiscountCodeIsValid(false);
      })
      .finally(() => {
        setIsSubmittingDiscount(false);
        reload();
      });
    setDiscountCode("");
    return true;
  }, [authFetch, createToast, discountCode, redirect, reload]);

  const highlightFeature = query.get("highlightFeature");
  const featureValue = query.get("featureValue");

  return (
    <Page
      fullWidth
      title="Account"
      breadcrumbs={[{ content: "Overview", onAction: () => navigate(-1) }]}
    >
      <Layout>
        <Layout.Section variant="fullWidth">
          <PlanFeatureMatrix
            highlightFeature={highlightFeature}
            featureValue={featureValue}
          />
        </Layout.Section>

        <Layout.Section variant="oneHalf">
          <Card title="Discount Code" sectioned>
            <Text as="h2" variant="headingMd">
              Discount Code
            </Text>
            <Form onSubmit={() => handleDiscountCodeSubmit()} implicitSubmit>
              <InlineStack wrap={false} align="start" gap="200">
                <div>
                  <FormLayout>
                    <FormLayout.Group condensed>
                      <TextField
                        id="discount_code"
                        type="text"
                        value={discountCode}
                        onChange={handleDiscountCodeChange}
                        label="Discount Code"
                        error={!discountCodeIsValid}
                        labelHidden
                      />
                    </FormLayout.Group>
                  </FormLayout>
                  <InlineError
                    message={discountErrorMessage}
                    fieldID="discount_code"
                  />
                </div>
                <Button loading={isSubmittingDiscount} submit>
                  Submit
                </Button>
              </InlineStack>
            </Form>
          </Card>
        </Layout.Section>

        <Layout.Section variant="oneHalf">
          <CreditsWidget />
        </Layout.Section>

        <Layout.Section variant="fullWidth">
          <SubscriptionHistoryTable />
        </Layout.Section>
      </Layout>

      <FooterHelp>
        {" "}
        Gift Reggie - &copy; {new Date().getUTCFullYear()}{" "}
        <Link
          monochrome
          removeUnderline
          href="http://moddapps.com/"
          target="_blank"
          rel="noreferrer"
        >
          Modd Apps
        </Link>
      </FooterHelp>
    </Page>
  );
}

function Subscriptions() {
  return (
    <PermissionGuard permission="account">
      <SubscriptionsContent />
    </PermissionGuard>
  );
}

export default Subscriptions;
