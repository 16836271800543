import React, { useCallback, useState, useEffect } from "react";

import {
  Card,
  Banner,
  Button,
  Modal,
  Select,
  BlockStack,
  Text,
} from "@shopify/polaris";

import deepEqual from "deep-equal";

import { useProfile, useRedirect, useFetch } from "../utils";
import LoadingAnimation from "./LoadingAnimation";

const PermissionBanner = ({
  newScopes = [],
  msg,
  buttonText = "Authorize App",
}) => {
  const authFetch = useFetch();
  const profile = useProfile()[0];
  const redirect = useRedirect();
  const scopes = String(profile?.shop?.scope).split(/,/);
  const shopOrigin = profile.shop.shop_origin;
  const [authorizationInProgress, setAuthorizationInProgress] = useState(false);

  const authorizeGiftListScopes = useCallback(async () => {
    setAuthorizationInProgress(true);
    try {
      const neededScopes = newScopes.filter((scope) => !scopes.includes(scope));
      if (neededScopes.length > 0) {
        await authFetch("/api/profile/scopes/add", {
          method: "post",
          json: { scopes: neededScopes },
        });
      }
      const { url } = await authFetch(`/api/login?shop=${shopOrigin}`).then(
        (r) => r.json()
      );
      redirect(url);
    } catch (ex) {
      console.warn("authorization request failed");
      setAuthorizationInProgress(false);
    }
  }, [authFetch, newScopes, redirect, scopes, shopOrigin]);

  return (
    <>
      <p>
        {msg}&nbsp;
        <Button
          plain
          type="button"
          onClick={authorizeGiftListScopes}
          disabled={authorizationInProgress}
          className="btn"
        >
          {authorizationInProgress ? "..." : buttonText}
        </Button>
      </p>
    </>
  );
};

const RegistryLegacySettings = ({
  giftListSettings,
  setGiftListSettings,
  // servergiftListSettings,
  // defaultGiftListSettings
}) => {
  const profile = useProfile()[0];

  const l = giftListSettings.list_settings.find(
    ({ id }) => id === "GiftList::Registry::Legacy"
  );
  if (!l) return <LoadingAnimation />;

  const currentScopes = profile?.shop?.scope.split(",");

  const needPurchaseScopes = !currentScopes.includes("write_draft_orders");
  const needGiftCardPurchaseScopes =
    !currentScopes.includes("write_draft_orders") &&
    !currentScopes.includes("write_products") &&
    !currentScopes.includes("write_gift_cards");

  return (
    <BlockStack gap="400">
      {l.name && <Text variant="headingSm">{l.name}</Text>}
      <Select
        label="Purchase Type"
        id="select-purchase-type"
        name="select-purchase-type"
        value={l?.backend_data?.["purchase-type"] ?? ""}
        options={[
          { label: "Disabled", value: "" },
          {
            label: "Product",
            value: "GiftList::Registry::Purchase",
          },
          {
            label: "Gift Card",
            value: "GiftList::Registry::GiftCardPurchase",
          },
        ]}
        onChange={(selected) => {
          const newList = giftListSettings.list_settings.find(
            ({ id }) => id === "GiftList::Registry::Legacy"
          );
          const otherLists =
            giftListSettings.list_settings.find(
              ({ id }) => id !== "GiftList::Registry::Legacy"
            ) || [];

          const newBackendData = newList.backend_data || {};
          newBackendData["purchase-type"] = selected;
          setGiftListSettings({
            ...giftListSettings,
            list_settings: [
              ...otherLists,
              { ...newList, backend_data: newBackendData },
            ],
          });
        }}
        helpText={
          <>
            {l?.backend_data?.["purchase-type"] ===
              "GiftList::Registry::Purchase" && (
              <>
                Uses draft orders for registry purchases.
                {needPurchaseScopes ? (
                  <PermissionBanner
                    newScopes={["write_draft_orders"]}
                    msg="App does not have permissions to use draft orders."
                  />
                ) : null}
              </>
            )}
            {l?.backend_data?.["purchase-type"] ===
              "GiftList::Registry::GiftCardPurchase" && (
              <>
                creates a gift-card for the exact amount. And the customer
                checks out with the created gift-card.
                {needGiftCardPurchaseScopes ? (
                  <PermissionBanner
                    newScopes={[
                      "write_draft_orders",
                      "write_products",
                      "write_gift_cards",
                    ]}
                    msg="App does not have permissions to use draft_orders write_products write_gift_cards"
                  />
                ) : null}
              </>
            )}
          </>
        }
      />
    </BlockStack>
  );
};

const GiftListSettings = () => {
  const authFetch = useFetch();
  const profile = useProfile()[0];

  const [loadingGlSettings, setLoadingGbSettings] = useState(true);
  const [servergiftListSettings, setServerGiftListSettings] = useState(null);
  const [giftListSettings, setGiftListSettings] = useState(null);
  const [defaultGiftListSettings, setDefaultGiftListSettings] = useState(null);

  // const [jsonBlockError, setJsonBlockError] = useState(null);

  const [debugModal, setDebugModal] = useState(false);

  useEffect(() => {
    if (loadingGlSettings) {
      authFetch("/api/lists/settings")
        .then((r) => r.json())
        .then((response) => {
          setServerGiftListSettings(response.merchant);
          setDefaultGiftListSettings(response.defaults);

          if (response.merchant) {
            setGiftListSettings(response.merchant);
          } else {
            setGiftListSettings(response.defaults);
          }
          setLoadingGbSettings(false);
        });
    }
  }, [loadingGlSettings, authFetch]);

  const changeRegistryTypeHook = useCallback(
    (newValue) => {
      const newRegistryType = newValue === "" ? null : newValue;
      setGiftListSettings({
        ...giftListSettings,
        registry_type: newRegistryType,
      });
    },
    [giftListSettings, setGiftListSettings]
  );

  const RegistrySelect = () => {
    const registryListType = defaultGiftListSettings.list_settings
      .filter((l) => l.type === "registry")
      .map((l) => ({
        label: l.name,
        value: l.id,
      }));

    return (
      <Select
        label="Registry Type"
        value={giftListSettings.registry_type || ""}
        options={[{ label: "None", value: "" }, ...registryListType]}
        name="registry_type"
        onChange={changeRegistryTypeHook}
        requiredIndicator
      />
    );
  };

  const RegistrySettings = useCallback(() => {
    let dom = <></>;

    switch (giftListSettings.registry_type) {
      case "GiftList::Registry::Legacy":
        dom = (
          <RegistryLegacySettings
            giftListSettings={giftListSettings}
            setGiftListSettings={setGiftListSettings}
          />
        );
        break;
      default:
        break;
    }

    return dom;
  }, [giftListSettings]);

  return (
    <Card
      title="Gift List Settings"
      sectioned
      actions={[
        {
          content: "save",
          onAction: () => {
            if (!JSON.stringify(giftListSettings)) return;
            authFetch("/api/lists/settings", {
              method: "post",
              json: giftListSettings,
            })
              .then((r) => r.json())
              .then((response) => {
                setServerGiftListSettings(response.merchant);
                setDefaultGiftListSettings(response.defaults);

                if (response.merchant) {
                  setGiftListSettings(response.merchant);
                } else {
                  setGiftListSettings(response.defaults);
                }
              });
          },
        },
        {
          content: "reload",
          onAction: () => {
            setGiftListSettings(null);
            setServerGiftListSettings(null);
            setLoadingGbSettings(true);
          },
        },
        {
          content: "debug",
          onAction: () => {
            setDebugModal(!debugModal);
          },
        },
      ]}
    >
      {!deepEqual(servergiftListSettings, giftListSettings) && (
        <Banner status="warning">You Have Unsaved Changes</Banner>
      )}
      <Modal
        large
        noScroll
        open={debugModal}
        title="Debug Gift List Settings"
        onClose={() => {
          setDebugModal(false);
        }}
      >
        <Modal.Section>
          <pre>{profile.shop.scope}</pre>
          <BlockStack gap={0} align="center">
            <Card title="Server">
              <pre>{JSON.stringify(servergiftListSettings, null, 2)}</pre>
            </Card>
            <Card title="Defaults">
              <pre>{JSON.stringify(defaultGiftListSettings, null, 2)}</pre>
            </Card>
            <Card title="DOM">
              <pre>{JSON.stringify(giftListSettings, null, 2)}</pre>
            </Card>
          </BlockStack>
        </Modal.Section>
      </Modal>
      {loadingGlSettings && (
        <BlockStack gap="400">
          <LoadingAnimation />
        </BlockStack>
      )}
      {!loadingGlSettings && (
        <BlockStack gap="400">
          <RegistrySelect />
          <RegistrySettings />
        </BlockStack>
      )}
    </Card>
  );
};

export default GiftListSettings;
