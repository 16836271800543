import React, { useState, useEffect, useCallback, useContext } from "react";
import StyleIsolation from "../components/StyleIsolation";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import LegacyMessageContext from "../components/LegacyMessageContext";
import { SubscriptionContext } from "../components/SubscriptionContext";

function APISettings() {
  const authFetch = useGiftReggieFetch();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const { readOnly } = useContext(SubscriptionContext);

  useEffect(() => {
    if (!loaded) {
      authFetch("/reggie/api", { headers: { Accept: "application/json" } })
        .then((response) => {
          console.log(response);
          setData(response);
          setLoaded(true);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Error getting page data.");
        });
    }
  }, [authFetch, loaded]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      authFetch(`/reggie/api`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Data after Submit", ex);
        });
    },
    [authFetch]
  );

  const storeId = data ? (
    <>
      <p>
        You have set up API access. Your credentials are below. You can use them
        to access Gift Reggie&apos;s API. The API documentation is located{" "}
        <a
          target="_blank"
          href="https://support.moddapps.com/hc/en-us/articles/1500003199582-Gift-Reggie-API-Documentation"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>

      <div className="row-fluid">
        <div className="span6">
          Store ID
          <input
            style={{ width: "100%" }}
            type="text"
            id="storefront-id"
            readOnly
            value={data.STORE_ID}
            aria-label="Store ID"
          />
        </div>
        <div className="span6">
          Access Token
          <input
            style={{ width: "100%" }}
            type="text"
            readOnly
            id="access_token"
            name="access-token"
            value={data.ACCESS_TOKEN}
            aria-label="Access Token"
          />
        </div>
      </div>

      <div className="row-fluid">
        <div className="span12">
          <button
            type="submit"
            className="btn-large btn-warning"
            name="action"
            value="update"
            disabled={readOnly}
          >
            Reset Access Token
          </button>{" "}
          <button
            type="submit"
            className="btn-large btn-danger"
            name="action"
            value="delete"
            disabled={readOnly}
          >
            Revoke API Access
          </button>
        </div>
      </div>
    </>
  ) : null;

  const notAllowed = data ? (
    <>
      <p>You need to be on the Expert plan to use the API.</p>
      <p>Please contact help@moddapps.com if you have any questions.</p>
      <button
        type="submit"
        className="btn-large btn-danger"
        name="action"
        value="delete"
        style={{ display: "none" }}
        disabled={readOnly}
      >
        Revoke API Access
      </button>
    </>
  ) : null;

  const noStoreId = data ? (
    <>
      <p>
        You haven&apos;t yet set up API access. You can activate API access for
        your store below.
      </p>

      <div className="row-fluid">
        <div className="span12">
          <button
            type="submit"
            name="action"
            className="btn-large btn-success"
            value="create"
            disabled={readOnly}
          >
            Activate API Access
          </button>
        </div>
      </div>
    </>
  ) : null;

  return data ? (
    <StyleIsolation>
      <LegacyContainer>
        <h3>API Access Information</h3>

        <p>
          This section allows you to set up api access to Gift Reggie via a web
          interface.
        </p>
        <form method="post" onSubmit={submit}>
          {data.ALLOWED ? (
            <>{data.STORE_ID ? storeId : noStoreId}</>
          ) : (
            notAllowed
          )}
        </form>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <APISettings />
  </LegacyMessageContext>
);
