import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";

import { App } from "./App";
import InstallPage from "./pages/InstallPage";
import Subscriptions from "./pages/Subscriptions";
import "@shopify/polaris/build/esm/styles.css";

import GiftListPage from "./GiftListPage";
import GiftListEditPage from "./GiftListEditPage";

import Dashboard from "./Dashboard";

import Registries from "./pages/Registries";
import Registry from "./pages/Registry";
import StockTracking from "./pages/StockTracking";
import ReverseStock from "./pages/ReverseStock";
import OrderStatus from "./pages/OrderStatus";
import Wishlists from "./pages/Wishlists";
import Wishlist from "./pages/Wishlist";
import Preferences from "./pages/Preferences";
import Templates from "./pages/Templates";
import Translations from "./pages/Translations";
import UsageBilling from "./pages/UsageBilling";
import APISettings from "./pages/APISettings";
import SMTPSettings from "./pages/SMTPSettings";
import Guide from "./pages/Guide";
import Credits from "./pages/Credits";

import GraphiQLPage from "./GraphiQLPage"

// import ContactSettings from "./pages/ContactSettings";

const urlParams = new URLSearchParams(window.location.search);
const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY;

let urlToken = urlParams.get("token");
const shopOrigin = urlParams.get("shop");
const host = urlParams.get("host");

try {
  if (urlParams.has("persist") && urlToken) {
    sessionStorage.setItem("jwtSessionToken", urlToken);
  } else {
    urlToken = urlToken || sessionStorage.getItem("jwtSessionToken");
  }
} catch (ex) {
  // session storage didn't work
}

// eslint-disable-next-line no-shadow
function AppComponent({ apiKey, host, shopOrigin, urlToken }) {
  const routes = [
    { name: "Dashboard", path: "/dashboard", element: <Dashboard /> },
    {
      name: "Registries",
      items: [
        {
          name: "Registries",
          path: "/registries",
          element: <Registries />,
          exact: true,
        },
        {
          path: "/registries/:id",
          element: <Registry />,
        },
        {
          name: "Stock Tracking",
          path: "/stock",
          element: <StockTracking />,
          exact: true,
        },
        {
          path: "/stock/:id",
          element: <ReverseStock />,
        },
        {
          name: "Order Status",
          path: "/orders",
          element: <OrderStatus />,
        },
      ],
    },
    { path: "/wishlists/:id", element: <Wishlist /> },
    {
      name: "Wishlists",
      path: "/wishlists",
      element: <Wishlists />,
      exact: true,
    },
    {
      name: "Preferences",
      items: [
        {
          name: "Settings",
          path: "/preferences",
          element: <Preferences />,
        },
        {
          name: "Templates",
          path: "/templates",
          element: <Templates />,
          exact: true,
        },
        { path: "/templates/:id", element: <Templates /> },
        {
          name: "Translations",
          path: "/translations",
          element: <Translations />,
          exact: true,
        },
        {
          path: "/translations/:id",
          element: <Translations />,
        },
      ],
    },
    // {
    //   name: "Setup",
    //   items: [
    //     { name: "Install Guide", path: "/guide", element:  <Guide /> },
    //     {
    //       name: "Integrations",
    //       path: "/integration",
    //       element:  <Integration />,
    //     },
    //     { name: "Styling", path: "/style", element:  <StyleGuide /> },
    //     { name: "POS Guide", path: "/posguide", element:  <PosGuide /> },
    //     {
    //       name: "General Documentation",
    //       path: "/documentation",
    //       element:  <GeneralDocumentation />,
    //     },
    //   ],
    // },
    {
      name: "Account",
      items: [
        // {
        //   name: "Contact",
        //   path: "/contact",
        //   render: () => <ContactSettings />,
        // },
        {
          name: "Account",
          path: "/account",
          element: <Subscriptions />,
        },
        {
          name: "Credits",
          path: "/credits",
          render: () => <Credits />,
        },
        {
          name: "Usage Billing",
          path: "/usage",
          element: <UsageBilling />,
        },
        { name: "API", path: "/apisetting", element: <APISettings /> },
        { name: "SMTP", path: "/smtp", element: <SMTPSettings /> },
        { name: "Setup Guide", path: "/guide", element: <Guide /> },
        {
          name: "Documentation",
          path: "https://support.moddapps.com/hc/en-us/categories/360005168274-Gift-Reggie",
          external: true,
        },
      ],
    },
    { path: "/transit/confirm", element: <Subscriptions /> },
    { path: "/transit/confirm_bundle", element: <Subscriptions /> },
    { path: "/login", access: "anonymous", element: <InstallPage /> },

    {
      path: "/gift_list",
      name: "Gift Lists",
      exact: true,
      element: <GiftListPage />,
    },
    {
      name: "Gift List Editor",
      path: "/gift_list/:id",
      element: <GiftListEditPage />,
    },{
      name: "Graphiql",
      path: "/graphiql",
      element: <GraphiQLPage />
    }
  
  
  
  ];


  return (
    <App
      apiKey={apiKey}
      host={host}
      shopOrigin={shopOrigin}
      urlToken={urlToken}
      routes={routes}
      globalContextComponent={React.Fragment}
      onProfileIsKnown={(profile) => {
        if (profile) {
          // setProfile(profile);
          try {
            window.dataLayer.push({
              shop_origin: profile.shop.shop_origin,
              shopify_plan: profile.shop.shopify_plan,
              shop_timezone: profile.shop.iana_timezone,
              days_installed: profile.shop.days_installed,
            });
          } catch (ex) {
            // do nothing
          }
        }
      }}
    />
  );
}

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppComponent
      apiKey={apiKey}
      host={host}
      shopOrigin={shopOrigin}
      urlToken={urlToken}
    />
  </React.StrictMode>
);
