/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";

import { Banner } from "@shopify/polaris";

import { DateTime } from "luxon";

import { useProfile, Link } from "../utils";
import { SubscriptionContext } from "./SubscriptionContext";


export default () => {
  const { activeSubscriptions, credits } = useContext(SubscriptionContext);

  const context = useProfile()[0];
  const activeSubscription = activeSubscriptions.filter(
    (s) => s.status === "active"
  );

  const banner = [];

  if (DateTime.fromISO(activeSubscription.trial_end_date) > DateTime.now())
    banner.push(
      <Banner
        title={`Trial Expires in ${activeSubscription[0].trial_days_left} days`}
        status="info"
      />
    );

  if (credits && credits.balance === credits.maximum_negative_balance){
    banner.push(
      <Banner title="Maximum Negative Credit Balance Reached" status="error">
        You have reached your maximum allowable negative credit balance. This
        has caused your account to lose access to most of Gift Reggie&:apos;s
        functionality. Please visit the <Link url="/credits">credits</Link> page
        to purchase more.
      </Banner>
    );
  } else if (credits && credits.balance < 0){
    banner.push(
      <Banner
        title="Negative Credit Balance"
        status="error"
      >
        Your credit balance is negative. This can cause various features in the application to be restricted or fail.
        Please visit the
        {" "}<Link url="/credits">
          credits
        </Link>{" "}
        page to purchase more.
      </Banner>
    );
  }
  

  if (activeSubscription[0]?.expiry)
    banner.push(
      <Banner
        title={`Your Subscription expires in ${Math.floor(DateTime.fromISO(activeSubscription[0].expiry).diff(DateTime.now(), "days").toObject().days)} days. Make sure to pick a new plan before it does to avoid service interuption.`}
        status="info"
      />
    );

  const pendingcharge = activeSubscriptions.find((s) => s.status === "pending");
  if (pendingcharge) {
    const otherSubscription = activeSubscriptions.filter(
      (s) => s.status === "active"
    );
    const action = context.user.account_owner
      ? {
          content: "Approve Charge",
          url: pendingcharge.confirmation_url,
        }
      : null;

    banner.push(
      <Banner
        title="Changes to your subscription"
        status={otherSubscription ? "warning" : "error"}
        action={action}
      >
        There is a pending charge on your account that must be accepted to
        activate your new subscription. Please click{" "}
        <Link external url={pendingcharge.confirmation_url}>
          here
        </Link>{" "}
        to be redirected to Shopify to accept the charge. Note, you will need
        appropriate permissions, if you encounter an error please ask the
        Shopify account owner to perform with action.
      </Banner>
    );
  }
  return <>{banner}</>;
};
