import React, { useState, useEffect, useCallback, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";
import { useProfile, useFetch, useRedirect, Link } from "../utils";
import LegacyContainer, {
  useGiftReggieFetch,
} from "../components/LegacyContainer";
import StyleIsolation from "../components/StyleIsolation";
import LegacyMessageContext from "../components/LegacyMessageContext";
import { SubscriptionContext } from "../components/SubscriptionContext";

// <script type='text/javascript' src="https://ajaxorg.github.io/ace-builds/src-noconflict/ace.js"></script>
// <script type='text/javascript' src='{data.JS_CATALYST_SRC}/oop-syntax-checker.js'></script>
// <style type='text/css'>
//	.ace_editor {
//		width: 100%;
//		height: 600px;
//	}
// </style>

// USP success

const ENDPOINT_INFO = {
  pos_streamlined: {
    description: "Streamlined view of the registry listing on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Template",
  },
  pos_snippet_registry_select: {
    description:
      "Snippet that is used to specify a particular registry on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Snippet",
  },
  pos_registry_header: {
    description:
      "Template that wraps the top of every POS template that displays an individual registry.",
    platform: "POS",
    visibility: "Staff",
    type: "Snippet",
  },
  pos_modify_registry: {
    description:
      "Template that shows when modifying a particular registry on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Template",
  },
  pos_view_registry: {
    description:
      "Template that shows when viewing a particular registry on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Template",
  },
  pos_mark_order: {
    description:
      "Template that shows the dialog for marking a particular completed order as being bought from a particular registry on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Template",
  },
  pos_mark_cart: {
    description:
      "Template that shows the dialog for marking a particular cart as being bought from a particular registry on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Template",
  },
  pos_main: {
    description:
      "Root template that gives you a choice between classic gift reggie and streamlined gift reggie on POS.",
    platform: "POS",
    visibility: "Staff",
    type: "Template",
  },
  pos_header: {
    description: "This template wraps the top of every POS template.",
    platform: "POS",
    type: "Snippet",
    visibility: "Staff",
  },
  pos_footer: {
    description: "This template wraps the bottom of every POS template.",
    platform: "POS",
    type: "Snippet",
    visibility: "Staff",
  },
  pos_error: {
    description:
      "This page is displayed in the case where you do not have sufficient privileges to access Gift Reggie via the POS.",
    platform: "POS",
    type: "Template",
    visibility: "Staff",
  },
  pos_add_cart_to_registry: {
    description:
      "This is the template that allows you to tag a particulcar cart on POS to a particular registry.",
    platform: "POS",
    type: "Template",
    visibility: "Staff",
  },
  pos_create_registry: {
    description:
      "This is the template that allows you to create registries on your POS.",
    platform: "POS",
    type: "Template",
    visibility: "Staff",
  },
  template_registry_printer: {
    description:
      "This template is used for printer-friendly versions of Gift Registries.",
    platform: "Printer",
    type: "Template",
    visibility: "Staff",
  },
  template_wishlist_printer: {
    description:
      "This template is used for printer-friendly versions of Wishlists.",
    platform: "Printer",
    type: "Template",
    visibility: "Staff",
  },
  proxy_find: {
    description:
      "This template is displayed when a user wants to search for a registry. Is the default landing page for /apps/giftregistry when user account integration is disabled.",
    url: ["/apps/giftregistry", "/apps/giftregistry/find"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_create: {
    description:
      "This template is displayed when a user wants to create a new registry.",
    url: ["/apps/giftregistry/create"],
    platform: "Web",
    type: "Template",
    visibility: "Registrant",
  },
  proxy_root: {
    description:
      "This template is displayed as the landing page for Gift Reggie, when user account integration is enabled.",
    url: ["/apps/giftregistry"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_cart_update: {
    description:
      "This template is used to render the update cart button inside the view registry page.",
    url: ["/apps/giftregistry/registry/{{ registry.id }}"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_closed: {
    description:
      "The template that's displayed a when a registry has been closed.",
    url: ["/apps/giftregistry/registry/{{ registry.id }}"],
    platform: "Web",
    type: "Template",
  },
  proxy_countries: {
    description: "Snippet that contains a country selector.",
    url: ["/apps/giftregistry/create", "/apps/giftregistry/profile"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_footer: {
    description:
      "This template wraps the bottom of all templates in Gift Reggie.",
    url: ["/apps/giftregistry/*"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_header: {
    description: "This template wraps the top of all templates in Gift Reggie.",
    url: ["/apps/giftregistry/*"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_login: {
    description:
      "This template is displayed when the user attempts to manage a registry, or attempts to perform some priveleged action while be logged out. A simple login form.",
    url: ["/apps/giftregistry/login"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_manage: {
    description:
      "This template is displayed when a user clicks 'Manage Registries' on the landing page. Displays all the registries that they have control of, or were invited to, in a table.",
    url: ["/apps/giftregistry/manage"],
    platform: "Web",
    type: "Template",
    visibility: "Registrant",
  },
  proxy_message: {
    description:
      "This template is used for the page where users can message the registrant (if enabled).",
    url: ["/apps/giftregistry/{{ registry.id }}/message"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_orders: {
    description:
      "This template is used to show the orders that have been placed off a registry for a gift registry owner.",
    url: ["/apps/giftregistry/{{ registry.id }}/orders"],
    platform: "Web",
    type: "Template",
    visibility: "Registrant",
  },
  proxy_profile: {
    description:
      "This template is displayed when a user wants to edit their registry's information.",
    url: ["/apps/giftregistry/{{ registry.id }}/profile"],
    platform: "Web",
    type: "Template",
    visibility: "Registrant",
  },
  proxy_registry_classic_view: {
    description: "This is the classic, table-based registry view.",
    url: ["/apps/giftregistry/{{ registry.id }}"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_registry_footer: {
    description:
      "This template is used to wrap the bottom of all registries (in addition to the normal Gift Reggie footer).",
    url: ["/apps/giftregistry/{{ registry.id }}"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_registry_header: {
    description:
      "This template is used to wrap the top of all registries (in addition to the normal Gift Reggie header).",
    url: ["/apps/giftregistry/{{ registry.id }}"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_registry_housekeeping: {
    description:
      "This snippet is used on the main registry page to do some housekeepin with regards to adding cart attributes to the cart.",
    url: ["/apps/giftregistry/{{ registry.id }}"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_registry_info: {
    description:
      "This template is essentially a list of all the registry fields, used in the profile and create pages.",
    url: [
      "/apps/giftregistry/{{ registry.id }}/profile",
      "/apps/giftregistry/{{ registry.id }}/create",
    ],
    platform: "Web",
    type: "Snippet",
    visibility: "Registrant",
  },
  proxy_share: {
    description:
      "The sharing page for managers of a particular registry. Has buttons by default that allow simple sharing to facebook and twitter.",
    url: ["/apps/giftregistry/{{ registry.id }}/share"],
    platform: "Web",
    type: "Template",
    visibility: "Registrant",
  },
  proxy_sharing_snippet: {
    description:
      "The snippet that rests on page which deals with the individual social sharing buttons.",
    url: [
      "/apps/giftregistry/{{ registry.id }}/share",
      "/apps/giftregistry/wishlist/{{ wishlist.id }}",
    ],
    platform: "Web",
    type: "Snippet",
    visibility: "Registrant",
  },
  proxy_signup: {
    description:
      "The default page for signing a customer up to your store, when they attempt to perform a priveleged action, such as creating a registry.",
    url: ["/apps/giftregistry/signup"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_error: {
    description: "The page used to display an error on Gift Reggie.",
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_styling: {
    description:
      "This template contains custom styling for particular themes, as well as the entrypoint for the 'CSS integration' feed.",
    url: ["/apps/giftregistry/*"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_user: {
    description:
      "This template includes a bunch of code necessary for validation and authentication of users using Gift Reggie.",
    url: ["/apps/giftregistry/*"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
  proxy_view: {
    description:
      "The main view for registries. Displays all sorts of information about the registry, to both guests and administrators.",
    url: ["/apps/giftregistry/{{ registry.id }}"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_view_password: {
    description:
      "This template is the landing page for registries that have been password protected.",
    url: ["/apps/giftregistry/{{ registry.id }}"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_wishlist: {
    description:
      "The main view for wishlists. Displays all sorts of information about the wishlist, to both guests and administrators.",
    url: ["/apps/giftregistry/wishlist/{{ wishlist.id }}"],
    platform: "Web",
    type: "Template",
    visibility: "Public",
  },
  proxy_wishlist_entry: {
    description:
      "The main view when a person doesn't have a wishlist, but would like to create one.",
    url: ["/apps/giftregistry/wishlist"],
    platform: "Web",
    type: "Template",
    visibility: "Registrant",
  },
  "snippet-giftreggie-checkout-button": {
    description:
      "Button used on the registry page to pre-fill a user's checkout.",
    url: ["/apps/giftregistry/registry/{{ registry.id }}"],
    platform: "Web",
    type: "Snippet",
    visibility: "Public",
  },
};

function Templates() {
  const [data, setData] = useState(null);
  const [loadedId, setLoadedId] = useState(null);
  const { readOnly } = useContext(SubscriptionContext);

  const { id } = useParams();
  const redirect = useRedirect();

  const fetch = useGiftReggieFetch();
  const authFetch = useFetch();
  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const selectedTemplateChange = useCallback(
    (e) => setSelectedTemplate(e.target.value),
    []
  );

  const templateInfoKey =
    data && data.ACTIVE_TEMPLATE_ID
      ? data.ACTIVE_TEMPLATE_PATH.replace(/\.(liquid|tmpl)/, "")
      : selectedTemplate;

  useEffect(() => {
    if (id !== loadedId) {
      const url = new URL(
        id ? `/reggie/templates/${id}` : `/reggie/templates`,
        window.location
      );
      fetch(url, { headers: { Accept: "application/json" } })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data", ex);
        })
        .finally(() => {
          setLoadedId(id);
        });
    }
  }, [fetch, id, loadedId]);

  const submit = useCallback(
    // eslint-disable-next-line consistent-return
    (e) => {
      e.preventDefault();
      if(readOnly)
        return 0;
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      fetch(id ? `/reggie/templates/${id}` : `/reggie/templates`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
        .then((nextData) => {
          setData(nextData);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data after Submit", ex);
        })
        .finally(() => {
          setLoadedId(id);
        });
    },
    [fetch, id, readOnly]
  );

  const createTemplate = useCallback(
    async (e) => {
      try {
        const path = e.target.form.elements.path.value;
        setData(null);
        await authFetch("/reggie/templates", {
          method: "POST",
          body: new URLSearchParams([
            ["myshopify_domain", shopOrigin],
            ["path", path],
            ["action", "create"],
          ]),
          redirect: "manual",
        });

        const { TEMPLATES } = await authFetch("/reggie/templates", {
          headers: { Accept: "application/json" },
        }).then((r) => r.json());

        const newTemplateId = TEMPLATES.find(({ PATH }) => PATH === path)?.ID;
        if (!newTemplateId) {
          throw new Error("Error finding created template");
        }
        redirect(`/templates/${newTemplateId}`);
      } catch {
        setLoadedId(null);
      }
    },
    [authFetch, redirect, shopOrigin]
  );

  const discard = useCallback(() => {
    setData(null);
    setLoadedId(null);
  }, []);

  const deleteForm = useCallback(
    (e) => {
      if (
        // "this is the way"
        // eslint-disable-next-line no-alert, no-restricted-globals
        confirm(
          `Are you sure you want to delete ${e.target.dataset.path}, reverting this template to its default state?`
        )
      ) {
        const targetId = e.target.dataset.id;
        const formData = new URLSearchParams([
          ["myshopify_domain", shopOrigin],
          ["action", "delete"],
        ]);
        authFetch(`/reggie/templates/${targetId}`, {
          method: "POST",
          body: formData,
        }).finally(() => {
          if (String(id) === String(targetId)) {
            redirect("/templates/");
          }
          setLoadedId(null);
        });
      }
    },
    [authFetch, id, redirect, shopOrigin]
  );

  const optionMarkup = useMemo(() => {
    const NAME_GROUP = Object.entries(ENDPOINT_INFO).map(
      ([name, { platform, type }]) => [name, `${platform} ${type}`]
    );
    const NAME_GROUP_MAP = new Map(NAME_GROUP);
    const GROUPS = [...new Set(NAME_GROUP.map((entry) => entry[1]))].sort();

    return data?.TYPES ? (
      <>
        <option value="">Select a Template</option>
        {GROUPS.map((group) => (
          <optgroup label={group} key={group}>
            {data.TYPES.map(
              (innerData) =>
                NAME_GROUP_MAP.get(
                  innerData.PATH.replace(/\.(liquid|tmpl)/, "")
                ) === group && (
                  <option key={innerData.PATH}>{innerData.PATH}</option>
                )
            )}
          </optgroup>
        ))}
      </>
    ) : null;
  }, [data?.TYPES]);

  return data && id === loadedId ? (
    <StyleIsolation>
      <LegacyContainer>
        <h3>Templates</h3>
        <hr />

        <p>
          Welcome to the template section. This is where advanced users who need
          extra flexibility in how their gift registry and wishlist pages are
          laid out can customize the templates used to display any page on their
          site.
        </p>
        <p>
          To get started, simply select the template you&apos;d like to
          customize from the dropdown below, and click create template. This
          will open the template for editing.
        </p>
        {data.TEMPLATE_SYSTEM_HTML_TEMPLATE ? (
          <p>
            Gift Reggie uses{" "}
            <a
              target="_blank"
              href="http://search.cpan.org/~wonko/HTML-Template-2.95/lib/HTML/Template.pm"
              rel="noreferrer"
            >
              HTML::Template
            </a>{" "}
            for rendering HTML on your site. The templating language is
            relatively straightforward. It is somewhat similar to Shopify&apos;s
            liquid templating system; it takes data that we enter into the
            templating system, and displays it in a manner that you specify,
            wrapped in HTML.
          </p>
        ) : (
          <p>
            Gift Reggie uses Shopify&apos;s liquid language for rendering HTML
            on your site. The templating language is relatively straightforward.
            It takes data that we enter into the templating system, and displays
            it in a manner that you specify, wrapped in HTML. You can see more
            details about the whole system here:{" "}
            <a
              target="_blank"
              href="https://docs.shopify.com/themes/liquid-documentation/basics"
              rel="noreferrer"
            >
              https://docs.shopify.com/themes/liquid-documentation/basics
            </a>
          </p>
        )}
        <p>
          If you have any questions, or would like access to data that is
          currently not accessible by a particular template, please send us an
          email at{" "}
          <a
            href="mailto:gift-reggie@eshopadmin.com"
            target="_blank"
            rel="noreferrer"
          >
            gift-reggie@eshopadmin.com
          </a>
          . We&apos;ll be happy assist you in whatever way we can.
        </p>
        <p>
          If you&apos;d ever like to revert to Gift Reggie&apos;s default
          template; simply delete the template by clicking on the &times; symbol
          next to its tab.
        </p>

        {data.CAN_TEMPLATE ? null : (
          <p className="upgrade-flavour">
            You cannot use the template editor on your current plan! If you have
            any templates, they will be inactive. Please upgrade to the
            Professional Plan or higher to use the template editor.
          </p>
        )}

        {data.TEMPLATE_SYSTEM_HTML_TEMPLATE ? (
          <>
            <p>
              Gift Reggie now has a new templating system! Please click below to
              upgrade.
            </p>
            <form method="post" onSubmit={submit}>
              <input type="hidden" name="version" value="2" />
              <input type="hidden" name="myshopify_domain" value={shopOrigin} />
              <button
                type="submit"
                name="action"
                id="upgrade-button"
                className="btn btn-success"
                value="upgrade"
              >
                Upgrade
              </button>
            </form>
          </>
        ) : (
          <>
            <p>
              You are using Gift Reggie&apos;s new liquid templating system. To
              revert to the old system, please click the button below.
            </p>
            <form method="post" onSubmit={submit}>
              <input type="hidden" name="version" value="1" />
              <input type="hidden" name="myshopify_domain" value={shopOrigin} />
              <button
                type="submit"
                name="action"
                className="btn btn-danger"
                value="upgrade"
              >
                Downgrade
              </button>
            </form>
          </>
        )}

        <hr />

        <div className="row-fluid">
          <div className="span12">
            <ul className="nav nav-tabs">
              {data.TEMPLATES.map((innerData) => (
                <li
                  key={innerData.ID}
                  style={{ padding: 0 }}
                  className={innerData.ACTIVE ? "active" : ""}
                >
                  <Link to={`/templates/${innerData.ID}`}>
                    {innerData.PATH}
                  </Link>
                </li>
              ))}
              {data.TYPES ? (
                <li className={data.ACTIVE_TEMPLATE_ID ? "" : "active"}>
                  <Link to="/templates">New Template +</Link>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
        <div className="row-fluid">
          <div className="span12">
            <form method="post" onSubmit={submit}>
              <input type="hidden" name="myshopify_domain" value={shopOrigin} />
              {data.ACTIVE_TEMPLATE_ID ? (
                <>
                  <div className="row-fluid">
                    <div className="span12">
                      <div id="message-display" hidden />
                      {id && id === loadedId && (
                        <textarea
                          key={data.ACTIVE_TEMPLATE_PATH}
                          className={
                            data.TEMPLATE_SYSTEM_HTML_TEMPLATE
                              ? "editor-liquid"
                              : ""
                          }
                          style={{
                            width: "100%",
                            height: "400px",
                            fontFamily: "monospace",
                          }}
                          name="contents"
                          defaultValue={data.ACTIVE_TEMPLATE_CONTENTS}
                          autoCapitalize="false"
                          autoComplete="false"
                          autoCorrect="false"
                          spellCheck="false"
                          disabled={readOnly}
                        />
                      )}
                    </div>
                  </div>
                  <p>
                    <button
                      type="submit"
                      disabled={readOnly || !+data.CAN_TEMPLATE}
                      className="btn btn-danger discard-changes"
                      onClick={discard}
                    >
                      Discard Changes
                    </button>{" "}
                    <button
                      type="submit"
                      disabled={readOnly || !+data.CAN_TEMPLATE}
                      name="action"
                      className="btn btn-success"
                      value="update"
                    >
                      Update Template
                    </button>{" "}
                    <button
                      type="button"
                      className="delete-form btn btn-danger"
                      data-id={data.ACTIVE_TEMPLATE_ID}
                      data-path={data.ACTIVE_TEMPLATE_PATH}
                      href="#"
                      onClick={deleteForm}
                      disabled={readOnly}
                    >
                      Delete This Template
                    </button>
                  </p>
                </>
              ) : (
                <>
                  {data.TYPES ? (
                    <div className="row-fluid">
                      <div className="span12">
                        <select
                          disabled={readOnly || !+data.CAN_TEMPLATE}
                          id="template-path"
                          name="path"
                          value={selectedTemplate}
                          onChange={selectedTemplateChange}
                        >
                          {optionMarkup}
                        </select>{" "}
                        <button
                          type="button"
                          disabled={readOnly || !+data.CAN_TEMPLATE}
                          className="btn btn-success"
                          onClick={createTemplate}
                        >
                          Create Template
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p>
                      You have already customized all your templates! Please
                      select one to edit from the list at the top of this page.
                    </p>
                  )}
                </>
              )}
            </form>
            {ENDPOINT_INFO[templateInfoKey] && (
              <div id="template-info" className="row-fluid">
                <div className="span12">
                  <hr />
                  <div>
                    <b>Description</b>:{" "}
                    {ENDPOINT_INFO[templateInfoKey].description}
                  </div>
                  <div>
                    <b>Template Platform</b>:{" "}
                    {ENDPOINT_INFO[templateInfoKey].platform}
                  </div>
                  <div>
                    <b>Template Type</b>: {ENDPOINT_INFO[templateInfoKey].type}
                  </div>
                  <div>
                    <b>Template Visibility</b>:{" "}
                    {ENDPOINT_INFO[templateInfoKey].visibility}
                  </div>

                  {ENDPOINT_INFO[templateInfoKey].url ? (
                    <div>
                      <b>Sample URLs</b>:{" "}
                      {ENDPOINT_INFO[templateInfoKey].url.map((url) => {
                        const mangledUrl = url
                          .replace(
                            /\{\{\s*registry.id\s*\}\}/g,
                            data.SAMPLE_REGISTRY_ID || ""
                          )
                          .replace(
                            /\{\{\s*wishlist.id\s*\}\}/g,
                            data.SAMPLE_WISHLIST_ID || ""
                          )
                          .replace("/apps/giftregistry", "");

                        return (
                          <React.Fragment key={mangledUrl}>
                            <br />
                            {/[*]/.test(mangledUrl) ? (
                              mangledUrl
                            ) : (
                              <a
                                target="_blank"
                                href={`//${shopOrigin}/apps/giftregistry${mangledUrl}`}
                                rel="noreferrer"
                              >
                                /apps/giftregistry{mangledUrl}
                              </a>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <Templates />
  </LegacyMessageContext>
);
