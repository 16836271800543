/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";

import { Card, Text } from "@shopify/polaris";

import { SubscriptionContext } from "./SubscriptionContext";


export default () => {
  const { credits } = useContext(SubscriptionContext);

  return <>
    <Card
      title="Credits"
    >
      <Text as="h2" variant="headingMd">
        Credits
      </Text>

      <p>Current Balance: <b>{credits.balance}</b></p>
      <p>Monthly Credicts Remaining: <b>{credits.monthly_balance}</b></p>
      <p>Expiring Credits: <b>{credits.expiring_balance}</b></p>
    </Card>
  </>;
};
