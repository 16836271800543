import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Page, Layout, Text, Card, Button, Form, DataTable, Checkbox, Pagination, Modal, TextContainer, InlineStack } from "@shopify/polaris";
import { useFetch, useRedirect, Link, useProfile } from "../utils";
import { useCreateModal, useCreateToast } from "../components/OverlayManager";
import PermissionGuard from "../components/Permissions";


function Credits() {
  const authFetch = useFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const queryConfirm = query.get("confirm");
  const [profile] = useProfile();
  const redirect = useRedirect();
  const createModal = useCreateModal();
  const createToast = useCreateToast();


  const [loaded, setLoaded] = useState(false);
  const [balance, setBalance] = useState(null);
  const [creditBundles, setCreditBundles] = useState(null);
  const [checkedWatchtower, setCheckedWatchtower] = useState(false);

  const [purchased, setPurchased] = useState(null);
  const [purchasedPage, setPurchasedPage] = useState(1);
  const [purchasedPageCount, setPurchasedPageCount] = useState(1);
  
  const [deducted, setDeducted] = useState(null);
  const [deductedPage, setDeductedPage] = useState(1);
  const [deductedPageCount, setDeductedPageCount] = useState(1);
  
  const [creditCharges, setCreditCharges] = useState(null);
  const [creditChargesPage, setCreditChargesPage] = useState(1);
  const [creditChargesPageCount, setCreditChargesPageCount] = useState(1);

  const [purchasing, setPurchasing] = useState(false);

  const reloadPage = useCallback(
    () => {
      setLoaded(false);
      authFetch("/api/credits/index")
      .then((r) => r.json())
      .then((response) => {
        setPurchased(response.purchased);
        setPurchasedPageCount(response.purchased_page_count);
        setDeducted(response.deducted);
        setDeductedPageCount(response.deducted_page_count);
        setBalance(response.balance);
        setCreditBundles(response.bundles);
        setCreditCharges(response.credit_charges);
        setCreditChargesPageCount(response.credit_charges_page_count);
      })
      .then(() => {
        setLoaded(true);
      });
    },
    [authFetch]
  )

  useEffect(() => {
    if (loaded === false) {
      reloadPage();
    }
  }, [loaded, reloadPage]);

  useEffect(() => {
    if (queryConfirm) {
      authFetch(`/api/credits/${encodeURIComponent(queryConfirm)}/confirm`)
        .then((r) => r.json())
        .then(() => {
          console.log("Confirmed credit charge.");
          setLoaded(false);
          navigate(-1);
        });
    }
  }, [authFetch, navigate, queryConfirm]);

  const handlePagination = useCallback(
    (table, page) => {
      authFetch(`/api/credits/pagination/${table}/${encodeURIComponent(page)}`)
        .then((r) => r.json())
        .then((response) => {
          switch(table) {
            case "purchased":
              setPurchased(response.purchased)
              break;
            case "deducted":
              setDeducted(response.deducted)
              break;
            case "creditcharges":
              setCreditCharges(response.credit_charges)
              break;
            default:
              console.log("Unexpected table type handling pagination")
          }
        })
    },
    [authFetch]
  );

  const reloadCharges = useCallback(
    () => {
      handlePagination('creditcharges', 1);
    },
    [handlePagination]
  )

  const handleCreditPurchase = useCallback(
    (id) => {
      setPurchasing(true);
      authFetch("/api/credits", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          credits: {
            id,
            checkedWatchtower
          }
        }),
        method: "POST",
      })
      .then(async (response) => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (profile.user.account_owner && data.redirect_to) {
          redirect(data.redirect_to);
          reloadCharges();
          return Promise.resolve(true);
        }
        if (data?.redirect_to) {
          createModal({
            title: "Approve Charge",
            secondaryActions: [{ content: "Close" }],
            render: () => (
              <Modal.Section>
                <TextContainer>
                  <Text variant="headingMd" as="h2">One More Thing...</Text>
                  <p>
                    In order to complete your credit purchase you&apos;ll need the store owner to approve the charge we just created.
                    According to our records {profile.shop.shop_owner} is the store owner, and their email is {profile.shop.email}
                    . You&apos;ll need to have them go to the following url to approve the charge. If you have the appropriate
                    permissions, it&apos;s possible that you can click on the link and approve the charge yourself.
                  </p>
                  <br />
                  {/* eslint-disable-next-line */}
                  <Link external url={data.redirect_to}>
                    {data.redirect_to}
                  </Link>
                </TextContainer>
              </Modal.Section>
            ),
          });
        } else if (data && 'redirect_to' in data) {
          createToast({ content: `Successfully added credits` });
        } else if (data?.message) {
          createToast({ content: `${data.message}` });
        }
        reloadPage();
        return Promise.resolve(true);
      })
      .then(() => {
        setPurchasing(false);
      })
      .catch((error) => {
        // TODO catch error this.setState({ errorMessage: error.toString() });
        // eslint-disable-next-line no-console
        console.error("There was an error!", error);
        createModal({
          title: "Error",
          primaryAction: { content: "Close" },
          render: () => (
            <Modal.Section>
              <Text variant="headingLg" as="p">There was an error purchasing credits. Please try again later.</Text>
            </Modal.Section>
          ),
        })
      });
    },
    [authFetch, redirect, checkedWatchtower, createModal, createToast, profile, reloadPage, reloadCharges]
  );
  

  return (
    <PermissionGuard permission="account">
      {() => (
        <Page fullWidth title="Credits" breadcrumbs={[{ content: "Overview", onAction: () => navigate(-1) }]}>
          <Layout>
            <Layout.Section>
              <Text variant="headingMd" as="h2">Credit Balance: {balance}</Text>
            </Layout.Section>
            
            <Layout.Section fullWidth>
              <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gridGap: "20px" }}>
                {creditBundles && creditBundles.map((item) => (
                  <div style={{ textAlign: "center"}} key={item.id}>
                    <Card title={`${item.amount} Credits`} sectioned>
                      {`${item.amount} Credits for $${item.price}`}
                      {item.plan_id ? "Plan Exlusive!" : ""}
                      <Form onSubmit={() => handleCreditPurchase(item.id)}>
                        { profile && profile.user && profile.user.watchtower ? (
                          <Checkbox
                            label="CS Gift Credits"
                            checked={checkedWatchtower}
                            onChange={setCheckedWatchtower}
                          />
                        ) : null}
                        <div style={{ marginTop: "15px" }}>
                          <Button submit primary loading={purchasing}>
                            Purchase
                          </Button>
                        </div>
                      </Form>
                    </Card>
                  </div>
                ))}
              </div>
            </Layout.Section>

            <Layout.Section fullWidth>
              <Text variant="headingMd" as="h2">Credit Influx</Text>
              <Card>
                <div style={{ padding: "15px"}}>
                  <DataTable
                    columnContentTypes={['text', 'text', 'numeric', 'numeric', 'text', 'text']}
                    headings={["Id", "Date", "Amount", "Used", "Type", "Expiry"]}
                    rows={
                      purchased &&
                      purchased.map((credit) => [
                        credit.id,
                        credit.created_at,
                        credit.amount,
                        credit.used,
                        credit.credit_type,
                        credit.expiry ? credit.expiry : "N/A",
                      ]) || []
                    }
                  />
                  <InlineStack 
                    distribution='center'
                    style={{ marginTop: "15px" }}
                  >
                    <Pagination
                      hasPrevious={purchasedPage > 1}
                      onPrevious={() => {
                        const newPage = purchasedPage - 1;
                        setPurchasedPage(newPage);
                        handlePagination('purchassed', newPage)
                      }}
                      hasNext={purchasedPage < purchasedPageCount}
                      onNext={() => {
                        const newPage = purchasedPage + 1;
                        setPurchasedPage(newPage);
                        handlePagination('purchassed', newPage)
                      }}
                    />
                  </InlineStack>
                </div>
              </Card>
            </Layout.Section>
            
            <Layout.Section fullWidth>
              <Text variant="headingMd" as="h2">Usage History</Text>
              <Card>
                <div style={{ padding: "15px"}}>
                  <DataTable
                    columnContentTypes={['text', 'text', 'numeric', 'numeric', 'text', 'text']}
                    headings={["Id", "Date", "Amount", "Credit Id Deducted From", "Type", "Reason"]}
                    rows={
                      deducted &&
                      deducted.map((credit) => [
                        credit.id,
                        credit.id,
                        credit.created_at,
                        credit.amount,
                        credit.credit_id ? credit.credit_id : "N/A",
                        credit.credit_type,
                        (credit.card_id && "Card") || (credit.expired && "Expired") || credit.reason || "N/A",
                      ]) || []
                    }
                  />
                  <InlineStack 
                    distribution='center'
                    style={{ marginTop: "15px" }}
                  >
                    <Pagination
                      hasPrevious={deductedPage > 1}
                      onPrevious={() => {
                        const newPage = deductedPage - 1;
                        setDeductedPage(newPage);
                        handlePagination('deducted', newPage)
                      }}
                      hasNext={deductedPage < deductedPageCount}
                      onNext={() => {
                        const newPage = deductedPage + 1;
                        setDeductedPage(newPage);
                        handlePagination('deducted', newPage)
                      }}
                    />
                  </InlineStack>
                </div>
              </Card>
            </Layout.Section>
            
            <Layout.Section fullWidth>
              <Text variant="headingMd" as="h2">Purchase History</Text>
              <Card>
                <div style={{ padding: "15px"}}>
                  <DataTable
                    columnContentTypes={['numeric', 'text', 'numeric', 'numeric', 'text', 'text']}
                    headings={["Id", "Date", "Price", "Credits", "Status", ""]}
                    rows={
                      creditCharges &&
                      creditCharges.map((charge) => [
                        charge.id,
                        charge.created_at,
                        charge.price,
                        charge.amount,
                        charge.status,
                        charge.confirmed ? "" : <Button primary onClick={() => redirect(charge.confirmation_url)}>Confirm Charge</Button>,
                      ]) || []
                    }
                  />
                  <InlineStack 
                    distribution='center'
                    style={{ marginTop: "15px" }}
                  >
                    <Pagination
                      hasPrevious={creditChargesPage > 1}
                      onPrevious={() => {
                        const newPage = creditChargesPage - 1;
                        setCreditChargesPage(newPage);
                        handlePagination('creditcharges', newPage)
                      }}
                      hasNext={creditChargesPage < creditChargesPageCount}
                      onNext={() => {
                        const newPage = creditChargesPage + 1;
                        setCreditChargesPage(newPage);
                        handlePagination('creditcharges', newPage)
                      }}
                    />
                  </InlineStack>
                </div>
              </Card>
            </Layout.Section>

          </Layout>
        </Page>
      )}
    </PermissionGuard>    
  );
}

export default Credits;
