import React, {useCallback} from "react";
import { GraphiQL } from "graphiql";
import { useFetch} from "./utils";
import "graphiql/graphiql.min.css";

const GraphiQLPage = () => {

  const authFetch = useFetch();

  const gqlFetcher = useCallback(
    async (graphQLParams) => {

      const proxyUrl = new URL(
        "api/shopify/unstable/graphql.json",
        window.location
      );

      const res = await fetch(proxyUrl, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(graphQLParams),
        credentials: "same-origin",
      });

      return res.json().catch(() => res.text());
    },
    [authFetch]
  );
  return <GraphiQL fetcher={gqlFetcher} />;
};

export default GraphiQLPage;
